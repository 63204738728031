import { Link } from "react-router-dom";
import preview from "../../images/what-to-blog-about.jpg";
import { Helmet } from "react-helmet";

function WhatToBlogAbout() {
  return (
    <div className="allblogcontent">
      <Helmet>
        <title>
          What to Blog About When You Don't Know What to Blog About | Code Every
          Damn Day
        </title>
        <meta
          name="description"
          content="Getting over the blogging imposter syndrome."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@planet_katie" />
        <meta name="twitter:creator" content="@planet_katie" />
        <meta name="twitter:image" content="../images/what-to-blog-about.jpg" />
        <meta
          name="twitter:title"
          content="What to Blog About When You Don't Know What to Blog About"
        />
        <meta
          name="twitter:description"
          content="Getting over the blogging imposter syndrome."
        />
      </Helmet>
      <div className="blogcontent">
        <div className="blogwrapper">
          <h2>What to Blog About When You Don't Know What to Blog About</h2>
          <p className="blogauthor">
            <strong>Author:</strong> Katie Reynolds
          </p>
          <p className="blogpublished">
            <strong>Published:</strong> September 4, 2021
          </p>
          <img
            src={preview}
            alt="Cartoon drawing of a bear sitting down and looking at a laptop with a question mark above his head, indicating that he is stumped"
          />
          <p>
            I used to be under the impression that you had to be an "industry
            professional" to write a blog. But I've been working in the same
            field for 12 years and I still don't feel qualified to write things
            for other people to read. Don't you need a journalism degree or
            something for that?
          </p>

          <p>
            Turns out, no, you don't. You don't need a degree, or a certificate,
            or even an audience! You just need a desire to write a blog.
          </p>

          <h3>How do I find a unique topic to blog about?</h3>
          <p>
            Until recently, whenever I'd have an idea for a blog, I'd run a
            Google search to see what else people had written about it. I would
            immediately get discouraged by the vast number of articles out there
            that already covered the same topic, and end up not writing anything
            at all.
          </p>

          <p>
            Why should I write another, just for it to get lost in the noise of
            the many articles that are already ranking highly in search results?
            What makes me think that I have something to say that all these
            other people haven't said already? How can I find something totally
            new to write about—that hasn't been done before?
          </p>

          <p>You don't.</p>

          <p>
            Whatever it is you want to write about, someone's covered it.
            Probably over and over again. Who cares?! Because you didn't write
            those articles, none of them will have your unique voice or insight.
            That's what will make your blog different from all the rest.
          </p>

          <h3>Ok but for real, what do I write about?</h3>
          <p>
            Did you just learn how to use CSS Flexbox? Write about CSS Flexbox!
          </p>

          <p>
            Have you just banged your head against a wall for two days straight
            trying to understand arrow functions? Write about your frustrations,
            what you did to take breaks and refresh your mind, and how you
            ultimately got the concept to "click" for you.
          </p>

          <p>
            Are you just starting to code and have no technical knowledge
            whatsoever? Write about how you've optimized your space for
            learning, or tell a story about where you are in your life and why
            you decided to start learning something new.
          </p>

          <p>
            Did you happen upon this blog as a non-developer? Write about your
            favorite travel destination, or the best barbecue restaurant in your
            town, or a foolproof method you've found to distract your dog while
            you trim his nails.
          </p>

          <p>
            You don't always have to teach something. Often, just sharing your
            personal experiences can help inspire others, or give them something
            they can relate to. No one's story is the same as yours.
          </p>

          <h3>How often should I blog?</h3>
          <p>Whenever you feel like it!</p>

          <p>
            Sure, it would be great to keep a weekly or monthly schedule and
            stick to it, but I find that people tend to abandon their goals
            altogether when they miss a milestone once or twice. Like it's not
            worth continuing because they didn't put a star on the chart for
            that week—and who wants to look at an incomplete star chart?
          </p>

          <p>
            Sometimes I'll go months without writing a blog, and then the muse
            will hit me and I'll knock out three in a week. Should I not post
            those blogs because it's been months since I posted my last one? Of
            course not. Consistent blogs are great, but inconsistent blogs are
            better than no blogs at all.
          </p>

          <p>
            Remember, you should write blogs first for yourself—to learn and
            grow and solidify your understanding of a topic—and second for your
            audience. That's not to say that you shouldn't consider your
            audience at all while writing a blog, but it's more important for
            you to take something away from your own blog than it is for you to
            entertain all the internet strangers.
          </p>
        </div>
        <h3 className="backtoallblogs">
          <Link to="/blog">&larr; Back to All Blogs</Link>
        </h3>
      </div>
    </div>
  );
}

export default WhatToBlogAbout;
