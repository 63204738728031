function PortfolioCard(props) {
  function expandCard() {
    const seemore = document.querySelectorAll(".seemore");
    seemore.forEach(function (e) {
      e.addEventListener("click", function () {
        const overlay = document.querySelector(".overlay");
        overlay.style.display = "block";
        const expandedCard = e.nextElementSibling;
        expandedCard.style.display = "block";
      });
    });
  }

  function closeCard() {
    const overlay = document.querySelector(".overlay");
    overlay.style.display = "none";
    const expandedCard = document.querySelectorAll(".expandedcard");
    expandedCard.forEach(function (card) {
      card.style.display = "none";
    });
  }

  return (
    <div className="portfoliocard">
      <div className="overlay" onClick={closeCard}></div>
      <div>
        <h2>{props.portfoliotitle}</h2>
      </div>
      <div className="portfoliointrocopy">
        <strong>Project Type: </strong>
        {props.portfoliotype}
      </div>
      <div className="portfoliointrocopy">
        <strong>Launched: </strong>
        {props.portfoliolaunched}
      </div>
      <div className="portfoliointrocopy">
        <strong>Link: </strong>
        <a href={props.portfoliolink}>
          <span>{props.portfoliotitle}</span>
        </a>
      </div>
      <div>
        <img
          className="portfolioimage"
          src={props.portfolioimage}
          alt={props.alt}
        />
      </div>
      <span className="projectlink seemore">
        <a href={props.projectlink}>
          <h3 className="seedetails" onClick={expandCard}>
            &#43; See Details
          </h3>
        </a>
      </span>
      <div className="expandedcard">
        <span class="closebutton" onClick={closeCard}>
          &#10005;
        </span>
        <div>
          <h2>{props.portfoliotitle}</h2>
        </div>
        <div className="portfoliointrocopy">
          <strong>Project Type: </strong>
          {props.portfoliotype}
        </div>
        <div className="portfoliointrocopy">
          <strong>Launched: </strong>
          {props.portfoliolaunched}
        </div>
        <div className="portfoliointrocopy">
          <strong>Link: </strong>
          <a href={props.portfoliolink}>
            <span>{props.portfoliotitle}</span>
          </a>
        </div>
        <div>
          <img
            className="portfolioimage"
            src={props.portfolioimage}
            alt={props.alt}
          />
        </div>
        <span className="projectdetails">{props.description}</span>
      </div>
    </div>
  );
}

export default PortfolioCard;
