import { Link } from "react-router-dom";
import preview from "../../images/writing-technical-documentation.jpg";
import outline from "../../images/writing-technical-documentation-outline.png";
import alttext from "../../images/writing-documentation-alt-text.png";
import screenshot from "../../images/writing-documentation-screenshot-example.png";
import links1 from "../../images/writing-documentation-links-1.png";
import links2 from "../../images/writing-documentation-links-2.png";
import links3 from "../../images/writing-documentation-links-3.png";
import links4 from "../../images/writing-documentation-links-4.png";
import { Helmet } from "react-helmet";

function WritingTechnicalDocumentation() {
  return (
    <div className="allblogcontent">
      <Helmet>
        <title>
          Writing Technical Documentation So Easy, a Caveman Can Do It | Code
          Every Damn Day
        </title>
        <meta
          name="description"
          content="An article that explains how to write technical documentation—in the style of technical documentation."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@planet_katie" />
        <meta name="twitter:creator" content="@planet_katie" />
        <meta
          name="twitter:image"
          content="../images/writing-technical-documentation.jpg"
        />
        <meta
          name="twitter:title"
          content="Writing Technical Documentation So Easy, a Caveman Can Do It"
        />
        <meta
          name="twitter:description"
          content="An article that explains how to write technical documentation—in the style of technical documentation."
        />
      </Helmet>
      <div className="blogcontent">
        <div className="blogwrapper">
          <h2>Writing Technical Documentation So Easy, a Caveman Can Do It</h2>
          <p className="blogauthor">
            <strong>Author:</strong> Katie Reynolds
          </p>
          <p className="blogpublished">
            <strong>Published:</strong> September 18, 2021
          </p>
          <img
            src={preview}
            alt="Cartoon drawing of an owl sitting on a tree branch and fixing his glasses with one wing while holding a pencil with the other"
          />
          <p>
            This article, written in the documentation style that it describes,
            is intended for people who will be writing technical documents or
            how-to guides for tools, programs, products, processes, etc.
          </p>
          <h3>Table of Contents</h3>
          <p>
            I.&nbsp;&nbsp;&nbsp;&nbsp;<a href="#summary">Summary</a>
            <br />
            II. &nbsp;&nbsp;<a href="#before">Before You Begin Writing</a>
            <br />
            III. &nbsp;<a href="#organizing">Organizing Your Document</a>
            <br />
            IV. &nbsp;<a href="#instructions">Writing Good Instructions</a>
            <br />
            V. &nbsp;&nbsp;<a href="#misc">Important Miscellaneous Info</a>
            <br />
            VI. &nbsp;<a href="#post">Post-Publishing</a>
          </p>
          <h3 id="summary">I. Summary</h3>
          <p>
            Writing technical documentation can be time-consuming and requires
            some research and planning ahead of time, but it doesn't have to be
            hard! This article is a living example of how you can lay out and
            organize your documentation for easy readability.
          </p>
          <p>
            I'm no technical writing expert, but I do write documentation quite
            often for my job, and have been told that my documentation is easy
            to understand, easy to navigate, and well-organized. While my style
            may not work for everyone and everything, I thought I'd share my
            approach.
          </p>
          <h3 id="before">II. Before You Begin Writing</h3>
          <p>
            <strong>A. Understand your topic</strong>
          </p>
          <p>
            It may go without saying, but you need to deeply understand the
            topic for which you are writing documentation. You should have used
            the software, process, etc enough to explain how it works clearly to
            someone with little to no knowledge of it.
          </p>
          <p>
            While you're writing, it's a good idea to follow your own steps to
            make sure that you are not leaving out anything you may have
            forgotten. For example, if you're telling us that a certain
            container on a web page lives in a tree structure that follows the
            path{" "}
            <strong>
              <code>Site &rarr; Home &rarr; Projects &rarr; Products</code>
            </strong>
            , double-check that path yourself to make sure you didn't forget a
            folder somewhere in the middle.
          </p>
          <p>
            It may very well be that the act of writing the documentation is
            your plan to understand it, which is fine. Just expect the process
            to take longer, as you are researching, trying things out for
            yourself, and writing about it all at the same time!
          </p>
          <p>
            <strong>B. Ask your peers</strong>
          </p>
          <p>
            Even if you feel that you know a topic very well, be sure to consult
            your peers who are also familiar with the topic to see if they have
            any tips or suggestions that you can include in your documentation.
            You may be well-versed on the main ideas, but a team member might
            remember some of the minor quirks about the product that a new user
            should be aware of, such as "that weird thing where you have to
            click the publish button twice if you're using Firefox."
          </p>
          <p>
            <strong>C. Choose your medium</strong>
          </p>
          <p>
            If you are writing documentation in a work setting, your team may
            already use a specific tool for documentation, such as Google Docs,
            <a
              href="https://guides.github.com/features/wikis/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              GitHub
            </a>{" "}
            or{" "}
            <a
              href="https://www.atlassian.com/software/confluence/why-wiki-collaboration-software"
              target="_blank"
              rel="noreferrer"
            >
              Atlassian's Confluence wiki
            </a>
            .
          </p>
          <p>
            I could write entirely separate documentation about how to use each
            of these tools to write documentation, but for the purposes of this
            article, I will assume you can use a basic Google Doc. Whatever you
            use, make sure your document is easy for future readers to access
            and edit.
          </p>
          <p>
            <strong>D. Outline your document</strong>
          </p>
          <p>
            Before you begin writing your actual document, list the main topics
            and subtopics you want to include. This could be as simple as
            jotting them down on a piece of paper or Notepad file. If you think
            of any little tidbits you don't want to forget, jot those down, too.
            You can fit them into their relevant sections later.
          </p>
          <p>
            Once you've got all your ideas out of your head, arrange them in a
            logical order that flows from start to finish. It'll be much easier
            to write your document when you have a plan in place.
          </p>
          <p>
            Here is an example of how the outline for this very documentation
            looked before I began writing. It has changed a bit between this
            outline and the final product, but the bones are there:
          </p>
          <p>
            <img
              src={outline}
              alt="A screenshot of an outline of a blog about how to write good technical documentation, showing a table of contents with section names"
            />
          </p>
          <h3 id="organizing">III. Organizing Your Document</h3>
          <p>
            <strong>A. Get started</strong>
          </p>
          <p>
            Open a new Google Doc, adjust your margins, layout, font, etc to
            your preferred settings, and begin writing a few introductory
            pieces:
          </p>
          <p>
            Start with a title that clearly states what your documentation will
            cover at the top of the page.
          </p>
          <p>
            Underneath the title, include a short snippet explaining who would
            benefit from this documentation. Is it for project managers who will
            be using a certain template to create reports? Is it for web content
            managers who will be updating pages in a proprietary CMS? For this
            article, my example snippet above reads:
          </p>
          <p>
            "This article, written in the documentation style that it describes,
            is intended for people who will be writing technical documents or
            how-to guides for tools, programs, products, processes, etc."
          </p>
          <p>
            Finally, be sure to include a Table of Contents. This article lists
            only the main sections in the Table of Contents above to save space,
            but in a real technical document, you would also include the
            subsections (the sections with lettered headings) nested underneath
            each of the main sections. You'll add anchor links to all of the
            section and subsection headings when you're finished writing.
          </p>
          <p>
            After you've written those three items, you can move on to the meat
            of your documentation.
          </p>
          <p>
            <strong>B. Define clear sections</strong>
          </p>
          <p>
            Each major section should contain subsections that directly relate
            to the main section's heading. In this example, you will find the
            subsection called "Outline your document" under the main section
            called "Before You Begin Writing" rather than, say, the main section
            called "Post-Publishing", obviously. Your sections should make
            sense.
          </p>
          <p>
            Go ahead and type out all of your section and subsection headings.
            You can adjust them later on if needed.
          </p>
          <p>
            <strong>C. Order your sections</strong>
          </p>
          <p>
            Your documentation should flow in some kind of chronological order
            from the first thing the user should do to the last, or from the
            most basic instruction to the most complicated. If you're teaching
            someone how to open a JIRA ticket, you wouldn't start with, "Assign
            the JIRA ticket to the necessary team." You would probably start
            with something like "Click 'Create' at the top of the page to start
            a new JIRA ticket."
          </p>
          <p>
            Read through each of the sections and subsections you typed and make
            sure they follow a logical order.
          </p>
          <p>
            <strong>D. Indent, number and space out your sections</strong>
          </p>
          <p>
            Indent each of your subsections, and then if you have any sections
            within those, indent them even more. Make it easy for your reader to
            interpret which subsections belong to which main topics.
          </p>
          <p>
            Pick a numbering system and stick to it. I tend to use roman
            numerals for main headings, then ABC lettering for subsections, then
            123 numbering if there are any step-by-step instructions or smaller
            sections within those subsections. You can use whatever you'd like,
            as long as you stay consistent.
          </p>
          <p>
            As you begin writing your content, add space between paragraphs and
            sections. Each paragraph should discuss one idea. Once a paragraph
            starts to get longer than 3-5 sentences, find a way to break it into
            two. It's much easier for the reader to scan through shorter
            paragraphs.
          </p>
          <h3 id="instructions">IV. Writing Good Instructions</h3>
          <p>
            <strong>A. Assume your audience knows nothing</strong>
          </p>
          <p>
            Ok, maybe not <em>nothing</em>... you can probably assume they know
            how to find their way around a computer. But write your instructions
            as if the readers have never in their lives seen the thing you are
            discussing, whether it's a software, tool or process. As the Reddit
            group says, try to "Explain like I'm 5." Don't overcomplicate things
            or use flowery language. These should be basic how-to instructions.
          </p>
          <p>
            <strong>B. Include screenshots</strong>
          </p>
          <p>
            It is almost <em>always</em> easier for the reader to quickly
            interpret visual guides than to read through long blocks of texts.
            Wherever possible, include screenshots of the process you're
            documenting. Bonus points if you include text along with the
            screenshot to clearly identify the areas you're describing.
          </p>
          <p>
            For example, let's say you're teaching your reader how to fill out a
            JIRA ticket for a specific team. You might include a screenshot of
            the JIRA form along with explanations about what to put in each
            field:
          </p>
          <p>
            <img
              src={screenshot}
              alt="A screenshot of an empty JIRA ticket, with written explanations next to each field describing what the user should type"
            />
          </p>
          <p>
            Don't forget to take accessibility into consideration. If you post a
            screenshot, you should include alt text that clearly describes the
            screenshot. To add alt text to an image in a Google Doc, simply
            right-click the image and choose "Alt text".
          </p>
          <p>
            <img
              src={alttext}
              alt="A Google Docs screenshot of a whitewater raft on the river, with the menu open highlighting the option you would click to add alt text"
            />
          </p>
          <p>
            You will then see a dialog box that allows you to enter your text.
            If there is text as part of your image, you should type all of that
            text into your alt text as well.
          </p>
          <p>
            Ideally, you should try to include any text descriptions as part of
            the document itself, rather them embedding descriptions into images.
          </p>
          <h3 id="misc">V. Important Miscellaneous Info</h3>
          <p>
            <strong>A. List important links</strong>
          </p>
          <p>
            You will probably have some external links related to your
            documentation that would be helpful to share. Don't forget to
            include links to other pages of documentation that go more in-depth
            about a topic you're discussing, example templates, any relevant
            tools or websites you mention, team dashboards, etc.
          </p>
          <p>
            <strong>B. List important people</strong>
          </p>
          <p>
            Include a table of teams or people that the reader may need to reach
            out to or work closely with, if applicable. Something like this:
          </p>
          <div className="teamtable">
            <span className="teamtableheader">
              <strong>Name:</strong>
            </span>
            <span className="teamtableheader">
              <strong>Team:</strong>
            </span>
            <span className="teamtableheader">
              <strong>Slack Handle:</strong>
            </span>
            <span className="teamtablelightgray">Harry Potter</span>
            <span className="teamtablelightgray">UI/UX Design</span>
            <span className="teamtablelightgray">@chosenone</span>
            <span className="teamtabledarkgray">Ron Weasley</span>
            <span className="teamtabledarkgray">Frontend Dev</span>
            <span className="teamtabledarkgray">@anotherweasley</span>
            <span className="teamtablelightgray">Hermione Granger</span>
            <span className="teamtablelightgray">Marketing</span>
            <span className="teamtablelightgray">@hgranger</span>
          </div>
          <p>
            For the purposes of this documentation, I've made the table of
            important people quite small. You can also add email addresses and
            more detailed explanations of how each person can help. "Reach out
            to Hermione for questions about copy and media", for example.
          </p>
          <h3 id="post">VI. Post-Publishing</h3>
          <p>
            <strong>A. Review and add Table of Contents links</strong>
          </p>
          <p>
            Once you're finished writing, reread your documentation and check
            for spelling errors, numbering errors, easy readability, etc.
          </p>
          <p>
            Don't forget to add anchor links from each section in the Table of
            Contents down to their relevant areas! Here's how you do this in
            Google Docs:
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. Go to the section header you want
            to link to and highlight the text.
          </p>
          <p>
            <img
              src={links1}
              alt="Screenshot of the words 'Example Social Media Posts' in Google Docs"
            />
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. Click "Insert", then "Bookmark".
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. You will see a little flag and the
            word "Bookmark" appear under your text.
          </p>
          <p>
            <img
              src={links2}
              alt="Screenshot of the words 'Example Social Media Posts' in Google Docs with the word 'Bookmark' underneath"
            />
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. Scroll up to your Table of Contents
            and highlight the text that you want to link down to the section you
            just bookmarked.
          </p>
          <p>
            <img
              src={links3}
              alt="Screenshot of the words 'Example Social Media Posts' in Google Docs"
            />
          </p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5. Click "Insert", then "Link".</p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6. You should see a list of sections
            you have bookmarked but not linked to yet. Choose the appropriate
            bookmark.
          </p>
          <p>
            <img
              src={links4}
              alt="Screenshot of the menu that allows you to create an anchor link in Google Docs"
            />
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7. Test your link. Click on the link
            you just created, and then click on the word "Bookmark" that pops
            up. This should take you down to the link's corresponding section.
          </p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8. Repeat for all other headings.</p>
          <p>
            <strong>B. Be open to comments and feedback</strong>
          </p>
          <p>
            Once you have published your documentation, share with your
            peers—both those who already know how to do what you have
            documented, and those who do not. Ask for feedback. Your
            "in-the-know" peers may notice something you've left out, or
            something you need to update. Your peers who are new to the topic
            you are writing about can let you know if any sections are unclear.
          </p>
          <p>
            <strong>C. Keep your documentation up to date</strong>
          </p>
          <p>
            How many times have you tried to Google how to do something, such as
            change an annoying setting on your phone, only to find that all the
            articles on the topic are from five years ago and the instructions
            no longer work on newer phones?
          </p>
          <p>
            Keeping your documentation up to date is perhaps the hardest part of
            writing documentation, because it's easy to publish your final
            document and forget about it. However, things change rapidly,
            especially in the technology field. Check your documentation often.
            Have any of the people in your "Important People" list left the
            company? Has the web address to one of the other wiki pages you link
            to changed?
          </p>
          <p>
            If you maintain many documents, it may be a good idea to set a
            reminder on your calendar every month to skim through them and
            ensure that nothing is out-of-date.
          </p>
          <p>
            If you're writing documentation mostly in a work setting, be sure
            that at least a couple of other people are familiar with your
            documents and have edit access in order to keep the documentation
            updated if you happen to leave the company.
          </p>
          <p>
            Good documentation keeps things running smoothly. Whether you're a
            technical writer or not—creating clean, detailed, easy-to-follow
            documentation isn't hard as long as you do your research, outline
            your document ahead of time and organize your sections effectively.
            Your present and future peers will thank you!
          </p>
        </div>
        <h3 className="backtoallblogs">
          <Link to="/blog">&larr; Back to All Blogs</Link>
        </h3>
      </div>
    </div>
  );
}

export default WritingTechnicalDocumentation;
