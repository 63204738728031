import { Link } from "react-router-dom";
import preview from "../../images/add-a-dynamic-footer.jpg";
import example from "../../images/copyright-footer-example.png";
import { Helmet } from "react-helmet";

function DynamicCopyrightYear() {
  return (
    <div className="allblogcontent">
      <Helmet>
        <title>
          Add a Dynamic Copyright Year to Your Website Footer in 60 Seconds |
          Code Every Damn Day
        </title>
        <meta
          name="description"
          content="Learn how to update the copyright year in your footer automatically using as few as two lines of code."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@planet_katie" />
        <meta name="twitter:creator" content="@planet_katie" />
        <meta
          name="twitter:image"
          content="../images/add-a-dynamic-footer.jpg"
        />
        <meta
          name="twitter:title"
          content="Add a Dynamic Copyright Year to Your Website Footer in 60 Seconds"
        />
        <meta
          name="twitter:description"
          content="Learn how to update the copyright year in your footer automatically using as few as two lines of code."
        />
      </Helmet>
      <div className="blogcontent">
        <div className="blogwrapper">
          <h2>
            Add a Dynamic Copyright Year to Your Website Footer in 60 Seconds
          </h2>
          <p className="blogauthor">
            <strong>Author:</strong> Katie Reynolds
          </p>
          <p className="blogpublished">
            <strong>Published:</strong> October 3, 2021
          </p>
          <img
            src={preview}
            alt="Cartoon drawing of a frog jumping from one lilypad that says 'Dec 31' to another lilypad that says 'Jan 1', with cattails poking out of the water in the background"
          />
          <p>
            Have you ever scrolled to the bottom of a web page and noticed that
            the footer says, "&copy; 2019", but the current year is 2021? It
            looks unprofessional, especially because it doesn't take much to
            make that copyright year "dynamic" so that it will update
            automatically each January 1. That way, you won't have to remember
            to update it manually yourself.
          </p>
          <blockquote>
            <p>
              <em>
                "I don't know who needs to hear this, but do not hardcode the
                copyright year into your footer."
              </em>
            </p>
            <p>- A tweet I saw</p>
          </blockquote>
          <p>
            I can't remember who tweeted that, but it reminded me that my
            copyright year was, indeed, hard-coded into my footer. I had quickly
            added the year to each of the pages on my site one evening, thinking
            I would update the year to pull in dynamically "later."
          </p>
          <p>
            The reality is that "later" could mean anywhere between next week
            and never, so before January hits and I inevitably forget to update
            the copyright year in my footer, I figured I'd take a minute to do
            so now.
          </p>
          <p>And you know what? A minute is all it took.</p>
          <h3>TL;DR:</h3>
          <p>
            There are several ways to add a dynamic copyright year into the
            footer of your site, but here's one easy method using
            <strong> HTML</strong> and <strong>JavaScript</strong>.
          </p>
          <p>
            <strong>
              Add the below snippet to each html file in your site, just above
              the closing <code>&lt;/body&gt;</code> tag:
            </strong>
          </p>
          <pre className="prettyprint">
            &lt;footer&gt;
            <br />
            &nbsp;&nbsp;&amp;copy; &lt;span
            class=&quot;year&quot;&gt;&lt;/span&gt; by [your name / company
            here] &lt;/footer&gt;
            <br />
            <br />
            &lt;script&gt;
            <br />
            &nbsp;&nbsp;const footerYear = document.querySelectorAll(".year");
            <br />
            &nbsp;&nbsp;footerYear.forEach(copyright =&gt; &#123;
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;copyright.innerHTML = new
            Date().getFullYear();
            <br />
            &nbsp;&nbsp;&#125;);
            <br />
            &lt;/script&gt;
          </pre>
          <p>Your footer should now look something like this:</p>
          <p>
            <img
              src={example}
              alt="Screenshot of a website footer that says 'Copyright 2001 by CodeEveryDamnDay'"
            />
          </p>
          <h3>Adding a Dynamic Footer to a Single-Page Site</h3>
          <p>
            If your site only has one page, such as a personal portfolio site
            with menu links that anchor down to each section on the page, adding
            a dynamic footer only takes two—yes, TWO—lines of code.
          </p>
          <p>
            <strong>
              Start by adding a simple <code>&lt;footer&gt;</code> section to
              the bottom of your page, just above the closing{" "}
              <code>&lt;/body&gt;</code> tag:
            </strong>
          </p>
          <pre className="prettyprint">
            &lt;footer&gt;
            <br />
            &nbsp;&nbsp;&amp;copy;&lt;span id=&quot;year&quot;&gt;&lt;/span&gt;
            by [your name / company here]
            <br />
            &lt;/footer&gt;
          </pre>
          <p>
            The <code>span</code> ensures that the copyright will stay on the
            same line as the rest of the text, as a <code>div</code> would break
            the line into three.
          </p>
          <p>Replace "your name / company here" with your own information.</p>
          <p>
            <strong>Just below the footer, add this line of JavaScript:</strong>
          </p>
          <pre className="prettyprint">
            &lt;script&gt;
            <br />
            &nbsp;&nbsp;document.getElementById("year").innerHTML =<br />
            &nbsp;&nbsp;new Date().getFullYear();
            <br />
            &lt;/script&gt;
          </pre>
          <p>
            In the first part, <code>document.getElementById("year")</code>, the
            js is searching your html file for an element with an id of "year".
            That would be the <code>span</code> tag that we just created.
          </p>
          <p>
            Once it finds that <code>span</code> tag, "<code>.innerHTML</code>"
            tells the js to replace the HTML content in that tag (which is
            currently empty), with whatever comes after the <code>=</code> sign.
          </p>
          <p>
            JavaScript has many built-in "Date" methods, one of which can
            retrieve the current year: <code>new Date().getFullYear();</code>.
            If you were to console log that method like this:
            <code>console.log(new Date().getFullYear())</code>, you would see{" "}
            <code>2021</code> return in the console at the time of this writing,
            as that is the current year. Therefore, the value that comes after
            the <code>=</code> sign is <code>2021</code>.
          </p>
          <p>
            After the browser runs the <code>getFullYear()</code> method and
            finds the current year, it will replace the HTML content of the{" "}
            <code>span</code> tag with that year, so the new tag would look like
            this if you check your html in the browser:
            <code> &lt;span id=&quot;year&quot;&gt;2021&lt;/span&gt;</code>
          </p>
          <p>
            That's it! You're done! If you'd like, you can add some additional
            copy after your name, like "All rights reserved."
          </p>
          <h3>Adding a Dynamic Footer to a Multi-Page Site</h3>
          <p>
            If you have multiple pages on your site, you probably don't want to
            add the same line of JavaScript to every single page manually, but
            rather paste it into a <code>script.js</code> file that you have
            linked to all of your pages.
          </p>
          <p>
            However, if you use the code above, you might find that your
            copyright year isn't pulling in correctly. Why is that?
          </p>
          <p>
            In the example code above, we are assuming one single page with one
            single footer element that has a <code>&lt;span&gt;</code> with an
            id of "year". If we add that same footer to all the other pages on
            our site and then ask the js file to apply the copyright year to the
            element with the id "year", the js file gets confused because there
            are multiple elements with an id of "year" – one on each page that
            is linked to our js file.
          </p>
          <p>
            An HTML <code>id</code> is meant to identify a unique element, so it
            should only exist once, even across multiple pages of the same site.
            If you want to apply some js that will act upon multiple similar
            elements across your site, you should use a<code> class</code>{" "}
            instead of an <code>id</code>.
          </p>
          <p>
            In case you have come to this article and jumped straight down to
            the "Multi-Page Site" section without reading the "Single-Page Site"
            section above, I will repeat some of the same explanations that I
            used in the "Single-Page" section.
          </p>
          <p>
            <strong>
              Add this <code>&lt;footer&gt;</code> section to the bottom of each
              of your <code>.html</code> pages, just above the closing
              <code> &lt;/body&gt;</code> tag:
            </strong>
          </p>
          <pre className="prettyprint">
            &lt;footer&gt;
            <br />
            &nbsp;&nbsp;&amp;copy; &lt;span
            class=&quot;year&quot;&gt;&lt;/span&gt; by [your name / company
            here]
            <br />
            &lt;/footer&gt;
          </pre>
          <p>
            The <code>span</code> ensures that the copyright will stay on the
            same line as the rest of the text, as a <code>div</code> would break
            the line into three.
          </p>
          <p>Replace "your name / company here" with your own information.</p>
          <p>
            <strong>
              On your <code>script.js</code> file
            </strong>{" "}
            (or whatever you named your js file)
            <strong>, add the following code to the bottom of the page:</strong>
          </p>
          <pre className="prettyprint">
            const footerYear = document.querySelectorAll(".year");
            <br />
            footerYear.forEach(copyright =&gt; &#123;
            <br />
            &nbsp;&nbsp;copyright.innerHTML = new Date().getFullYear();
            <br />
            &#125;);
          </pre>
          <p>
            A few things are going on here. First,{" "}
            <code>document.querySelectorAll(".year")</code> finds all of the
            elements with a class of "year" throughout the html pages that are
            linked to your js file. These would be the <code>span</code> tags
            that we just created. This process will return an array of all of
            those <code>span</code> tags, which we have assigned to a variable
            called <code>footerYear</code>.
          </p>
          <p>
            We can't just replace the innerHTML of <code>footerYear</code> with
            something, because <code>footerYear</code> is an array rather than
            an element. Instead, we will need to loop through the array and
            perform the innerHTML replacement on each element in turn.
          </p>
          <p>
            There are a few ways to loop through an array, but I used this
            method:
          </p>
          <p>
            <code>footerYear.forEach(copyright =&gt; &#123;</code>
          </p>
          <p>
            This first line is basically saying, "Hey, for each item in the
            array called <code>footerYear</code>, I'm going to do
            something."Each item is given a sort of placeholder name called
            "copyright" in this example, though you can use whatever placeholder
            you want, like "x" or "el" (for element) or even "orange." It
            doesn't matter, as long as you're consistent in the following line:
          </p>
          <code>copyright.innerHTML = new Date().getFullYear();</code>
          <p>
            Now, we are telling the js to take each of the "copyright" elements
            and replace the <code>innerHTML</code> with whatever comes after the{" "}
            <code>=</code> sign.
          </p>
          <p>
            JavaScript has many built-in "Date" methods, one of which can
            retrieve the current year: <code>new Date().getFullYear();</code>.
            If you were to console log that method like this:
            <code>console.log(new Date().getFullYear())</code>, you would see
            <code> 2021</code> return in the console at the time of this
            writing, as that is the current year.
          </p>
          <p>
            After the browser runs the <code>getFullYear()</code> method and
            finds the current year, it will replace the HTML content of each of
            the <code>span</code> tags in our array with that year, so each{" "}
            <code>span</code> tag will now look like this if you check it out
            the html in your browser:
            <code>&lt;span class=&quot;year&quot;&gt;2021&lt;/span&gt;</code>
          </p>
          <p>
            Lastly, don't forget to close out your loop with{" "}
            <code>&#125;);</code>
          </p>
          <p>
            And there you have it! Adding a dynamic copyright year to your
            footer now will save you time in the future and prevent your site
            from looking outdated and unprofessional. Just set it and forget it!
          </p>
        </div>
        <h3 className="backtoallblogs">
          <Link to="/blog">&larr; Back to All Blogs</Link>
        </h3>
      </div>
    </div>
  );
}

export default DynamicCopyrightYear;
