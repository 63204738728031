import BlogPreviewCard from "./BlogPreviewCard";
import technicaldocumentation from "../images/writing-technical-documentation.jpg";
import dynamicfooter from "../images/add-a-dynamic-footer.jpg";
import codesnippets from "../images/how-to-display-html-tags-with-custom-code-snippets.jpg";
import whattoblogabout from "../images/what-to-blog-about.jpg";

function BlogPreviews() {
  window.addEventListener("load", function () {
    const allblogs = document.getElementsByClassName("blogpreviewcard");
    return (
      allblogs[0].outerHTML + allblogs[1].outerHTML + allblogs[2].outerHTML
    );
  });

  return (
    <div className="blogpreviewsbg">
      <div className="blogintro">
        <h2>Blog</h2>
        <div>Insights from a web veteran but coding rookie</div>
      </div>
      <div className="blogpreviews">
        <BlogPreviewCard
          blogpreviewtitle="How to Display HTML Tags with Custom Code Snippets"
          blogpreviewdate="October 24, 2021"
          blogpreviewimage={codesnippets}
          alt="Cartoon drawing of an html tag, with two alligators forming the 'less than' and 'greater than' brackets with their open mouths"
          blogpreviewsnippet="A guide to creating your own code snippets instead of embedding them from third-party websites."
          blogpreviewlink="/blog/custom-code-snippets"
        />
        <BlogPreviewCard
          blogpreviewtitle="Add a Dynamic Copyright Year to Your Website Footer in 60 Seconds"
          blogpreviewdate="October 3, 2021"
          blogpreviewimage={dynamicfooter}
          alt="Cartoon drawing of a frog jumping from one lilypad that says 'Dec 31' to another lilypad that says 'Jan 1', with cattails poking out of the water in the background"
          blogpreviewsnippet="Learn how to update the copyright year in your footer automatically using as few as two lines of code."
          blogpreviewlink="/blog/dynamic-copyright-year"
        />
        <BlogPreviewCard
          blogpreviewtitle="Writing Technical Documentation So Easy, a Caveman Can Do It"
          blogpreviewdate="September 18, 2021"
          blogpreviewimage={technicaldocumentation}
          alt="Cartoon drawing of an owl sitting on a tree branch and fixing his glasses with one wing while holding a pencil with the other"
          blogpreviewsnippet="An article that explains how to write technical documentation—in the style of technical documentation."
          blogpreviewlink="/blog/writing-technical-documentation"
        />
        <BlogPreviewCard
          blogpreviewtitle="What to Blog About When You Don't Know What to Blog About"
          blogpreviewdate="September 4, 2021"
          blogpreviewimage={whattoblogabout}
          alt="Cartoon drawing of a bear sitting down and looking at a laptop with a question mark above his head, indicating that he is stumped"
          blogpreviewsnippet="Getting over the blogging imposter syndrome."
          blogpreviewlink="/blog/what-to-blog-about"
        />
      </div>
    </div>
  );
}

export default BlogPreviews;
