import BlogPreviews from "../components/BlogPreviews";
import { Helmet } from "react-helmet";

function Blog() {
  return (
    <div>
      <Helmet>
        <title>Blog | Code Every Damn Day</title>
        <meta
          name="description"
          content="Insights from a web veteran but beginning coder"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@planet_katie" />
        <meta name="twitter:creator" content="@planet_katie" />
        <meta name="twitter:image" content="../images/what-to-blog-about.jpg" />
        <meta name="twitter:title" content="Blog | Code Every Damn Day" />
        <meta
          name="twitter:description"
          content="Insights from a web veteran but beginning coder"
        />
      </Helmet>
      <BlogPreviews />
    </div>
  );
}

export default Blog;
