import heroimage from "../images/hero-image.png";

function Hero() {
  return (
    <div className="herobanner">
      <img
        src={heroimage}
        className="heroimage"
        alt="Drawing of a cartoon mouse sitting atop a spool of thread, working on a laptop computer which sits atop some books. A rounded door shows a countryside outside the window, with a potted plant on the floor."
      />
    </div>
  );
}

export default Hero;
