import { Link } from "react-router-dom";

function BlogCard(props) {
  return (
    <div className="blogpreview otherblogs">
      <span>
        <h3>{props.blogtitle}</h3>
      </span>
      <span>{props.blogdate}</span>
      <span>
        <img className="blogimage" src={props.blogimage} alt={props.alt} />
      </span>
      <span>{props.blogsnippet}</span>
      <span className="seemore">
        <Link to={props.bloglink}>
          <h3>Read More &gt;</h3>
        </Link>
      </span>
    </div>
  );
}

export default BlogCard;
