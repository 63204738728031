import { Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import Home from "./pages/Home";
import Resume from "./pages/Resume";
import Portfolio from "./pages/Portfolio";
import Blog from "./pages/Blog";
import ScrollToTop from "./ScrollToTop";
import StickyNav from "./components/StickyNav";
import MobileMenu from "./components/MobileMenu";
import Hero from "./components/Hero";
import Footer from "./components/Footer";
import WhatToBlogAbout from "./pages/blogs/WhatToBlogAbout";
import WritingTechnicalDocumentation from "./pages/blogs/WritingTechnicalDocumentation";
import DynamicCopyrightYear from "./pages/blogs/DynamicCopyrightYear";
import CustomCodeSnippets from "./pages/blogs/CustomCodeSnippets";
// import { useState } from "react";

const NoMatch = () => {
  return <h3>This page does not exist.</h3>;
};

export default function App() {
  window.addEventListener("hashchange", function () {
    window.scrollTo(window.scrollX, window.scrollY - 50);
  });

  let menuOpen = false;

  function menuToggle() {
    const menuBtn = document.querySelector(".hamburgermenu");
    const mobileMenu = document.querySelector(".mobilemenu");

    if (!menuOpen) {
      menuBtn.classList.add("open");
      menuOpen = true;
      mobileMenu.style.opacity = "1";
      mobileMenu.style.height = "100%";
      mobileMenu.style.zIndex = "5";
      console.log(menuOpen);
    } else {
      menuBtn.classList.remove("open");
      menuOpen = false;
      mobileMenu.style.opacity = "0";
      mobileMenu.style.height = "0";
      mobileMenu.style.zIndex = "0";
      console.log(menuOpen);
    }
  }

  // const [menuOpen, setIsOpen] = useState(false);
  // let toggleState = () => setIsOpen(!menuOpen);

  return (
    <div className="maincontent">
      <Helmet>
        <title>Code Every Damn Day</title>
        <meta
          name="description"
          content="Documenting my frontend development learning journey while creating websites, apps and games from scratch"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@planet_katie" />
        <meta name="twitter:creator" content="@planet_katie" />
        <meta name="twitter:image" content="../images/hero-media-preview.jpg" />
        <meta name="twitter:title" content="Code Every Damn Day" />
        <meta
          name="twitter:description"
          content="Documenting my frontend development learning journey while creating websites, apps and games from scratch"
        />
      </Helmet>
      <ScrollToTop />
      <StickyNav menutoggle={menuToggle} />
      <MobileMenu menutoggle={menuToggle} />
      <Hero />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/resume" element={<Resume />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/what-to-blog-about" element={<WhatToBlogAbout />} />
        <Route
          path="/blog/writing-technical-documentation"
          element={<WritingTechnicalDocumentation />}
        />
        <Route
          path="/blog/dynamic-copyright-year"
          element={<DynamicCopyrightYear />}
        />
        <Route
          path="/blog/custom-code-snippets"
          element={<CustomCodeSnippets />}
        />
        <Route path="*" element={<NoMatch />} />
      </Routes>
      <Footer />
    </div>
  );
}
