import About from "../components/About";
import Skills from "../components/Skills";
import LatestBlog from "../components/LatestBlog";
import FeaturedProject from "../components/FeaturedProject";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <div>
      <Helmet>
        <title>Home | Code Every Damn Day</title>
      </Helmet>
      <About />
      <Skills />
      <FeaturedProject />
      <LatestBlog />
    </div>
  );
}

export default Home;
