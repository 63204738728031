import { Link } from "react-router-dom";
import instagram from "../images/instagram.png";
import twitter from "../images/twitter.png";
import linkedin from "../images/linkedin.png";

export default function StickyNav(props) {
  return (
    <div className="stickynav">
      <div className="stickynavsocials">
        <a
          href="https://www.instagram.com/planet.katie/"
          target="_blank"
          rel="noreferrer"
        >
          <span className="stickysocials">
            <img src={instagram} className="socialimg" alt="Instagram Logo" />
          </span>
        </a>
        <a
          href="https://twitter.com/thediydev"
          target="_blank"
          rel="noreferrer"
        >
          <span className="stickysocials">
            <img src={twitter} className="socialimg" alt="Twitter Logo" />
          </span>
        </a>
        <a
          href="https://www.linkedin.com/in/katiemreynolds/"
          target="_blank"
          rel="noreferrer"
        >
          <span className="stickysocials">
            <img src={linkedin} className="socialimg" alt="LinkedIn Logo" />
          </span>
        </a>
      </div>
      <div className="navmenu">
        <span className="navitem">
          <h2 id="homelink">
            <Link to="/">Home</Link>
          </h2>
        </span>
        <span className="navitem">
          <h2 id="resumelink">
            <Link to="/resume">Resume</Link>
          </h2>
        </span>
        <span className="navitem">
          <h2 id="portfoliolink">
            <Link to="/portfolio">Portfolio</Link>
          </h2>
        </span>
        <span className="navitem">
          <h2 id="bloglink">
            <Link to="/blog">Blog</Link>
          </h2>
        </span>
      </div>
      <div className="hamburgermenu" onClick={props.menutoggle}>
        <div className="hamburgerbutton"></div>
      </div>
    </div>
  );
}
