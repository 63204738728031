import { Link } from "react-router-dom";

export default function MobileMenu(props) {
  return (
    <div className="mobilemenucontainer">
      <div className="mobilemenu" onClick={props.menutoggle}>
        <span className="mobilenavitem">
          <h3 id="homemobilelink">
            <Link to="/">Home</Link>
          </h3>
        </span>
        <span className="mobilenavitem">
          <h3>
            <Link to="/resume">Resume</Link>
          </h3>
        </span>
        <span className="mobilenavitem">
          <h3>
            <Link to="/portfolio">Portfolio</Link>
          </h3>
        </span>
        <span className="mobilenavitem">
          <h3>
            <Link to="/blog">Blog</Link>
          </h3>
        </span>
      </div>
    </div>
  );
}
