import { Link } from "react-router-dom";
import profilepic from "../images/profilepic-resume.png";
import instagram from "../images/instagram.png";
import linkedin from "../images/linkedin.png";
import twitter from "../images/twitter.png";
import html from "../images/html.png";
import css from "../images/css.png";
import javascript from "../images/javascript.png";
import react from "../images/react.png";
import wordpress from "../images/wordpress.png";
import sitecore from "../images/sitecore.png";
import contentful from "../images/contentful.png";
import tridion from "../images/tridion.png";
import jira from "../images/jira.png";
import photoshop from "../images/photoshop.png";
import office from "../images/office.png";
import vscode from "../images/vscode.png";
import asana from "../images/asana.png";
import mailchimp from "../images/mailchimp.png";
import seo from "../images/seo.png";
import abtesting from "../images/abtesting.png";
import analytics from "../images/analytics.png";
import figma from "../images/figma.png";
import uiux from "../images/uiux.png";
import github from "../images/github.png";
import sql from "../images/sql.png";

function ResumeContent() {
  return (
    <div className="resumecontainer">
      <div className="resumebody">
        <div className="resumetop">
          <div className="resumeintro">
            <span className="resumephoto">
              <img
                className="resumeprofilepic"
                src={profilepic}
                alt="resumephoto"
              />
            </span>
            <span className="resumeintrocopy">
              <h2>Katie Reynolds</h2>
              <h3>Web Content Manager & Front-End Developer</h3>
              <span className="resumelocationemail">
                Orlando, FL&nbsp;&nbsp;|&nbsp;&nbsp;
                <a href="mailto:reynoldsmkatie@gmail.com">Email</a>
              </span>
              <span className="resumesociallinks">
                <a
                  href="https://www.linkedin.com/in/katiemreynolds/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="socialimg"
                    src={linkedin}
                    alt="LinkedIn Logo"
                  />
                </a>
                <a
                  href="https://twitter.com/thediydev"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="socialimg" src={twitter} alt="Twitter Logo" />
                </a>
                <a
                  href="https://www.instagram.com/planet.katie/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="socialimg"
                    src={instagram}
                    alt="Instagram Logo"
                  />
                </a>
              </span>
            </span>
          </div>
        </div>
        <div className="resumeleft">
          <div className="leftsection">
            <div className="leftresumeitem">
              <h3>SPARK NOTES</h3>
              <span>
                10+ years in web content management with additional experience
                in scrum / agile, project management and marketing. Currently
                learning front-end web development.
              </span>
            </div>
            <div className="leftresumeitem">
              <h3>EDUCATION</h3>
              <span>
                <strong>University of South Florida | Tampa, FL</strong>
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bachelor of Science in
                Marketing | 2010
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Master of Science in
                Entrepreneurship | 2012
              </span>
            </div>
            <div className="leftresumeitem">
              <h3>LINKS</h3>
              <span>
                <strong>Portfolio:</strong>
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Link to="/portfolio">codeeverydamnday.com/portfolio</Link>
                <br />
                <strong>Personal Website:</strong>
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Link to="/">codeeverydamnday.com</Link>
                <br />
                <strong>LinkedIn:</strong>
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a
                  href="https://www.linkedin.com/in/katiemreynolds/"
                  target="_blank"
                  rel="noreferrer"
                >
                  linkedin.com/in/katiemreynolds
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="resumeright">
          <div className="rightsection">
            <h3>SKILLS</h3>
            <h4>Tools:</h4>
            <div className="skillsicons">
              <div className="resumeskillicon">
                <img src={html} alt="HTML Logo" />
                <span className="skillname">HTML</span>
              </div>
              <div className="resumeskillicon">
                <img src={css} alt="CSS Logo" />
                <span className="skillname">CSS</span>
              </div>
              <div className="resumeskillicon">
                <img src={javascript} alt="JavaScript Logo" />
                <span className="skillname">JavaScript</span>
              </div>
              <div className="resumeskillicon">
                <img src={react} alt="React Logo" />
                <span className="skillname">React</span>
              </div>
              <div className="resumeskillicon">
                <img src={wordpress} alt="WordPress Logo" />
                <span className="skillname">WordPress</span>
              </div>
              <div className="resumeskillicon">
                <img src={sitecore} alt="Sitecore Logo" />
                <span className="skillname">Sitecore</span>
              </div>
              <div className="resumeskillicon">
                <img src={contentful} alt="Conentful Logo" />
                <span className="skillname">Contentful</span>
              </div>
              <div className="resumeskillicon">
                <img src={tridion} alt="Tridion Logo" />
                <span className="skillname">Tridion</span>
              </div>
              <div className="resumeskillicon">
                <img src={jira} alt="JIRA Logo" />
                <span className="skillname">JIRA</span>
              </div>
              <div className="resumeskillicon">
                <img src={photoshop} alt="Photoshop Logo" />
                <span className="skillname">Photoshop</span>
              </div>
              <div className="resumeskillicon">
                <img src={office} alt="Microsoft Office Logo" />
                <span className="skillname">Office</span>
              </div>
              <div className="resumeskillicon">
                <img src={vscode} alt="Visual Studio Code Logo" />
                <span className="skillname">VS Code</span>
              </div>
              <div className="resumeskillicon">
                <img src={asana} alt="Asana Logo" />
                <span className="skillname">Asana</span>
              </div>
              <div className="resumeskillicon">
                <img src={mailchimp} alt="Mailchimp Logo" />
                <span className="skillname">Mailchimp</span>
              </div>
              <div className="resumeskillicon">
                <img src={seo} alt="SEO Logo" />
                <span className="skillname">SEO</span>
              </div>
              <div className="resumeskillicon">
                <img src={abtesting} alt="A/B Testing Logo" />
                <span className="skillname">A/B Testing</span>
              </div>
              <div className="resumeskillicon">
                <img src={analytics} alt="Analytics Logo" />
                <span className="skillname">Analytics</span>
              </div>
              <div className="resumeskillicon">
                <img src={figma} alt="Figma Logo" />
                <span className="skillname">Figma</span>
              </div>
              <div className="resumeskillicon">
                <img src={uiux} alt="UI/UX Logo" />
                <span className="skillname">UI/UX</span>
              </div>
              <div className="resumeskillicon">
                <img src={github} alt="GitHub Logo" />
                <span className="skillname">GitHub</span>
              </div>
              <div className="resumeskillicon">
                <img src={sql} alt="SQL Logo" />
                <span className="skillname">SQL</span>
              </div>
            </div>
            <h4>Other:</h4>
            <span className="otherskillsbullets">
              <li>Scrum Master Certified</li>
              <li>
                Comfortable providing remote 24/7 on-call support across time
                zones
              </li>
              <li>Strong technical writing and English grammar skills</li>
            </span>
          </div>
        </div>
        <div className="resumebottom">
          <h3>EXPERIENCE</h3>
          <div className="experiencesection">
            <h4>Matterport: Sr. Web Content Manager</h4>
            <h4 className="experiencedates">December 2021 - Present</h4>
            <span className="experiencedescription">
              • Spearhead the web content strategy on matterport.com
              <br />
              • Update and create new content through a CMS (Drupal) and coding
              (HTML/CSS/JavaScript)
              <br />• Work closely with Design, Marketing, Engineering and other
              teams to develop web enhancements and facilitate projects
            </span>
          </div>
          <div className="experiencesection">
            <h4>Disney Parks & Resorts Digital: Digital (Web) Producer</h4>
            <h4 className="experiencedates">November 2014 - December 2021</h4>
            <span className="experiencedescription">
              • Coordinate with cross-functional teams to build web landing
              pages and complete content requests for all digital ticket and
              resort hotel products across disneyworld.com and disneyland.com,
              including multi-language translations
              <br />• Work with developers to build new CMS functionality based
              on some given code, easing build process for future producers
              <br />• Identify and resolve or escalate risks and conflicts on an
              on-call basis to ensure that sites are functional 24/7
              <br />• Occasionally lead status calls, QA test new features in
              lower environments and write detailed documentation
              <br />• Led a tech studio in a vertical producer and agile scrum
              master role by managing JIRA dashboards, scrum boards, ticket
              priorities, sprint planning, estimation sessions and daily scrum
              calls
              <br />• Managed multi-million dollar budgets, including
              forecasting and vendor costs, to ensure that team members were
              resourced appropriately based on scope of current and upcoming
              projects and sustainment items
            </span>
          </div>
          <div className="experiencesection">
            <h4>
              Astound Media: Content & Social Media Manager (Remote / Part-Time)
            </h4>
            <h4 className="experiencedates">August 2019 - August 2020</h4>
            <span className="experiencedescription">
              • Managed all content, social media, email marketing and weekly
              blog posts for several small business websites using WordPress,
              Mailchimp, Asana, Postcron, and SEMRush
            </span>
          </div>
          <div className="experiencesection">
            <h4>
              SeaWorld Parks & Entertainment: Interactive Marketing Coordinator
            </h4>
            <h4 className="experiencedates">May 2013 - November 2014</h4>
            <span className="experiencedescription">
              • Maintained web content for SeaWorld Orlando, Aquatica Orlando
              and Discovery Cove by auditing website content on a quarterly
              basis, updating content daily using HTML and CSS, and executing
              quick-turnaround changes as requested
              <br />• Worked with internal stakeholders to build, test, publish
              and maintain eCommerce products including theme park tickets,
              promotional campaign products and reservation-based ancillary
              products
              <br />• Supported UAT testing of corporate digital initiatives
              such as mobile app activations, new websites and mini sites, folio
              (in-park cashless payments connected to user accounts), internal
              web site search and checkout optimization
              <br />• Gained experience with project management, interactive
              marketing budgets and strategic partnerships by leading the
              digital efforts of a new sea lion and otter show at the park
            </span>
          </div>
          <div className="experiencesection">
            <h4>Web Direct Brands: Web Marketing Assistant</h4>
            <h4 className="experiencedates">February 2008 - August 2010</h4>
            <span className="experiencedescription">
              • Managed database of thousands of web products and continually
              updated product information using a CMS by editing website HTML,
              writing technical product descriptions, editing and uploading
              product images and managing all SEO-related advertising using
              Google AdWords and Google Analytics
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

// function ResumeContent() {
//   return (
//     <div className="resumecontent">
//       <div className="resumeintro">
//         <span className="resumephoto">
//           <img
//             className="resumeprofilepic"
//             src={profilepic}
//             alt="resumephoto"
//           />
//         </span>
//         <span className="resumeintrocopy">
//           <h2>Katie Reynolds</h2>
//           <h3>Web Producer</h3>
//           <span className="resumelocationemail">
//             Orlando, FL | <a href="mailto:reynoldsmkatie@gmail.com">Email</a>{" "}
//           </span>
//           <span className="resumesociallinks">
//             <a
//               href="https://www.linkedin.com/in/katiemreynolds/"
//               target="_blank"
//               rel="noreferrer"
//             >
//               <img className="socialimg" src={linkedin} alt="LinkedIn Logo" />
//             </a>
//             <a
//               href="https://twitter.com/planet_katie"
//               target="_blank"
//               rel="noreferrer"
//             >
//               <img className="socialimg" src={twitter} alt="Twitter Logo" />
//             </a>
//             <a
//               href="https://www.instagram.com/planet.katie/"
//               target="_blank"
//               rel="noreferrer"
//             >
//               <img className="socialimg" src={instagram} alt="Instagram Logo" />
//             </a>
//           </span>
//         </span>
//       </div>
//       <div className="resumebody">
// <div className="leftsection">
//   <div className="leftresumeitem">
//     <h3>SPARK NOTES</h3>
//     <span>
//       10+ years in web content management with additional experience in
//       scrum / agile, project management and marketing. Currently
//       learning front-end web development, while tracking my progress at{" "}
//       <a
//         href="https://codeeverydamnday.com/"
//         target="_blank"
//         rel="noreferrer"
//       >
//         CodeEveryDamnDay.com.
//       </a>
//     </span>
//   </div>
//   <div className="leftresumeitem">
//     <h3>EDUCATION</h3>
//     <span>
//       <strong>University of South Florida | Tampa, FL</strong>
//       <br />
//       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bachelor of Science in
//       Marketing | 2010
//       <br />
//       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Master of Science in
//       Entrepreneurship | 2012
//     </span>
//   </div>
//   <div className="leftresumeitem">
//     <h3>LINKS</h3>
//     <span>
//       <strong>Portfolio:</strong>
//       <br />
//       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//       <a
//         href="https://codeeverydamnday.com/portfolio.html"
//         target="blank"
//       >
//         codeeverydamnday.com/portfolio.html
//       </a>
//       <br />
//       <strong>Personal Website:</strong>
//       <br />
//       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//       <a href="https://codeeverydamnday.com/" target="blank">
//         codeeverydamnday.com
//       </a>
//       <br />
//       <strong>LinkedIn:</strong>
//       <br />
//       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//       <a
//         href="https://www.linkedin.com/in/katiemreynolds/"
//         target="blank"
//       >
//         linkedin.com/in/katiemreynolds
//       </a>
//     </span>
//   </div>
// </div>
//         <div className="rightsection">
//           <div className="rightresumeitem">
//             <h3>SKILLS</h3>
//             <h4>Tools:</h4>
//             <div className="toolsicons">
//               <div>
//                 <table>
//                   <tr>
//                     <td>
//                       <img src={html} alt="HTML Logo" />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>HTML</td>
//                   </tr>
//                 </table>
//               </div>
//               <div>
//                 <table>
//                   <tr>
//                     <td>
//                       <img src={css} alt="CSS Logo" />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>CSS</td>
//                   </tr>
//                 </table>
//               </div>
//               <div>
//                 <table>
//                   <tr>
//                     <td>
//                       <img src={javascript} alt="JavaScript Logo" />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>JavaScript</td>
//                   </tr>
//                 </table>
//               </div>
//               <div>
//                 <table>
//                   <tr>
//                     <td>
//                       <img src={react} alt="React Logo" />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>React</td>
//                   </tr>
//                 </table>
//               </div>
//               <div>
//                 <table>
//                   <tr>
//                     <td>
//                       <img src={wordpress} alt="WordPress Logo" />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>WordPress</td>
//                   </tr>
//                 </table>
//               </div>
//               <div>
//                 <table>
//                   <tr>
//                     <td>
//                       <img src={sitecore} alt="Sitecore Logo" />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>Sitecore</td>
//                   </tr>
//                 </table>
//               </div>
//               <div>
//                 <table>
//                   <tr>
//                     <td>
//                       <img src={contentful} alt="Contentful Logo" />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>Contentful</td>
//                   </tr>
//                 </table>
//               </div>
//               <div>
//                 <table>
//                   <tr>
//                     <td>
//                       <img src={tridion} alt="Tridion Logo" />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>Tridion</td>
//                   </tr>
//                 </table>
//               </div>
//               <div>
//                 <table>
//                   <tr>
//                     <td>
//                       <img src={jira} alt="Jira Logo" />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>JIRA</td>
//                   </tr>
//                 </table>
//               </div>
//               <div>
//                 <table>
//                   <tr>
//                     <td>
//                       <img src={photoshop} alt="Photoshop Logo" />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>Photoshop</td>
//                   </tr>
//                 </table>
//               </div>
//               <div>
//                 <table>
//                   <tr>
//                     <td>
//                       <img src={office} alt="Microsoft Office Logo" />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>Office</td>
//                   </tr>
//                 </table>
//               </div>
//               <div>
//                 <table>
//                   <tr>
//                     <td>
//                       <img src={vscode} alt="Visual Studio Code Logo" />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>VS Code</td>
//                   </tr>
//                 </table>
//               </div>
//               <div>
//                 <table>
//                   <tr>
//                     <td>
//                       <img src={asana} alt="Asana Logo" />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>Asana</td>
//                   </tr>
//                 </table>
//               </div>
//               <div>
//                 <table>
//                   <tr>
//                     <td>
//                       <img src={mailchimp} alt="Mailchimp Logo" />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>Mailchimp</td>
//                   </tr>
//                 </table>
//               </div>
//               <div>
//                 <table>
//                   <tr>
//                     <td>
//                       <img src={seo} alt="SEO Logo" />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>SEO</td>
//                   </tr>
//                 </table>
//               </div>
//               <div>
//                 <table>
//                   <tr>
//                     <td>
//                       <img src={abtesting} alt="A/B Testing Logo" />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>A/B Testing</td>
//                   </tr>
//                 </table>
//               </div>
//               <div>
//                 <table>
//                   <tr>
//                     <td>
//                       <img src={analytics} alt="Analytics Logo" />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>Analytics</td>
//                   </tr>
//                 </table>
//               </div>
//               <div>
//                 <table>
//                   <tr>
//                     <td>
//                       <img src={figma} alt="Figma Logo" />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>Figma</td>
//                   </tr>
//                 </table>
//               </div>
//               <div>
//                 <table>
//                   <tr>
//                     <td>
//                       <img src={uiux} alt="UI Design Logo" />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>UI Design</td>
//                   </tr>
//                 </table>
//               </div>
//               <div>
//                 <table>
//                   <tr>
//                     <td>
//                       <img src={github} alt="GitHub Logo" />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>GitHub</td>
//                   </tr>
//                 </table>
//               </div>
//               <div>
//                 <table>
//                   <tr>
//                     <td>
//                       <img src={sql} alt="SQL Logo" />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>SQL</td>
//                   </tr>
//                 </table>
//               </div>
//             </div>
//             <h4>Other Skills</h4>
//             <span className="otherskillsbullets">
//               <li>Scrum Master Certified</li>
//               <li>
//                 Comfortable providing remote 24/7 on-call support across time
//                 zones
//               </li>
//               <li>Strong technical writing and English grammar skills</li>
//             </span>
//           </div>
//         </div>
//       </div>
//       <div className="workexperience">
//         <div className="disney">
//           <span classname="experiencetitle">Disney</span>
//           <span className="experiencedates">Date Rage</span>
//         </div>
//         <div className="astound">
//           <span classname="experiencetitle">Astound</span>
//           <span className="experiencedates">Date Rage</span>
//         </div>
//         <div className="seaworld">
//           <span classname="experiencetitle">SeaWorld</span>
//           <span className="experiencedates">Date Rage</span>
//         </div>
//         <div className="webdirectbrands">
//           <span classname="experiencetitle">Web Direct Brands</span>
//           <span className="experiencedates">Date Rage</span>
//         </div>
//       </div>
//     </div>
//   );
// }

export default ResumeContent;
