import { Link } from "react-router-dom";
import SkillItem from "./SkillItem";
import html from "../images/html.png";
import css from "../images/css.png";
import javascript from "../images/javascript.png";
import react from "../images/react.png";
import wordpress from "../images/wordpress.png";
import sitecore from "../images/sitecore.png";
import contentful from "../images/contentful.png";
import tridion from "../images/tridion.png";
import jira from "../images/jira.png";
import photoshop from "../images/photoshop.png";
// import office from "../images/office.png";
// import vscode from "../images/vscode.png";
import asana from "../images/asana.png";
import mailchimp from "../images/mailchimp.png";
import seo from "../images/seo.png";
// import abtesting from "../images/abtesting.png";
// import analytics from "../images/analytics.png";
import figma from "../images/figma.png";
// import uiux from "../images/uiux.png";
// import github from "../images/github.png";
// import sql from "../images/sql.png";

function Skills() {
  return (
    <div className="skills">
      <span className="skillstitle">
        <h1>Skills</h1>
      </span>
      <div className="skillslist">
        <SkillItem icon={html} alt="HTML Logo" text="HTML" />
        <SkillItem icon={css} alt="CSS Logo" text="CSS" />
        <SkillItem icon={javascript} alt="JavaScript Logo" text="JavaScript" />
        <SkillItem icon={react} alt="React Logo" text="React" />
        <SkillItem icon={wordpress} alt="WordPress Logo" text="WordPress" />
        <SkillItem icon={sitecore} alt="Sitecore Logo" text="Sitecore" />
        <SkillItem icon={contentful} alt="Contentful Logo" text="Contentful" />
        <SkillItem icon={tridion} alt="Tridion Logo" text="Tridion CMS" />
        <SkillItem icon={jira} alt="JIRA Logo" text="JIRA" />
        <SkillItem
          icon={photoshop}
          alt="Photoshop Logo"
          text="Photoshop"
        />{" "}
        <SkillItem icon={asana} alt="Asana Logo" text="Asana" />
        <SkillItem icon={mailchimp} alt="Mailchimp Logo" text="Mailchimp" />
        <SkillItem icon={seo} alt="SEO Logo" text="SEO" />
        <SkillItem icon={figma} alt="Figma Logo" text="Figma" />
      </div>
      <span className="seealllink">
        <h2>
          <Link to="/resume">See Full Resume</Link>
        </h2>
      </span>
    </div>
  );
}

export default Skills;
