import PortfolioCard from "./PortfolioCard";
import tracethetrail from "../images/pf-trce-trl.png";
import cedd from "../images/pf-cedd.png";
import hundreddays from "../images/pf-100.png";
import dragondrop from "../images/pf-drgn-drp.png";
import gps from "../images/pf-gps-cnvrtr.png";
import rocketblaster from "../images/pf-rckt-blstr.png";
import swgs from "../images/pf-swgs.png";
import whackazombie from "../images/pf-whckzmbie.png";
import isitsnowing from "../images/pf-isitsnwng.png";
import Codepen from "react-codepen-embed";

function PortfolioCards() {
  const CodepenSnowing = () => {
    return <Codepen hash="JjroKjY" user="reynoldsmkatie" />;
  };
  const CodepenZombie = () => {
    return <Codepen hash="gOxGPdM" user="reynoldsmkatie" />;
  };
  const CodepenRocket = () => {
    return <Codepen hash="zYwpwrV" user="reynoldsmkatie" />;
  };
  const CodepenGPS = () => {
    return <Codepen hash="GRmKeLw" user="reynoldsmkatie" />;
  };
  const CodepenDragon = () => {
    return <Codepen hash="ZEeOjjz" user="reynoldsmkatie" />;
  };

  return (
    <div className="portfoliocardsbg">
      <div className="portfoliointro">
        <h2>Portfolio</h2>
        <div>
          A collection of games, apps, websites and other projects built from
          scratch
        </div>
      </div>
      <div className="portfoliocards">
        <PortfolioCard
          portfoliotitle="Is it Snowing?"
          portfoliotype="Web App"
          portfoliolaunched="November 2021"
          portfoliolink="https://www.codeeverydamnday.com/projects/isitsnowing/index.html"
          portfolioimage={isitsnowing}
          alt="A cartoon snow scene with a modal that shows an input field with the prompt 'Enter a city to see if it's snowing'"
          description={
            <>
              <h3>Description:</h3>
              <p>
                My partner loves the snow, and is always ready to drop
                everything and drive to the nearest place it's snowing (which
                can be far, as we live in Florida).
              </p>
              <p>
                I intended to build an app that shows you where it's snowing
                closest to you based on your location or any other location you
                enter. Unfortunately, after much research and collaboration with
                other developers, I concluded that there doesn't seem to exist
                an API that can perform a lookup of many cities at once and
                return the city with snow closest to the location you enter.
              </p>
              <p>
                Instead, the weather API's allow you only to perform a lookup
                for one city at a time. I entertained the idea of running
                hundreds of calls at once for every major city in America,
                concatenating all the JSON data, and pulling out the closest
                city with "snow" in the data based on its latitude and longitude
                coordinates in comparison to your entered city. However, API
                call limits prevent me from that.
              </p>
              <p>
                In the end, I decided to work within the limits of the API's and
                simply create an app that tells you whether or not it's snowing
                in the city you enter.
              </p>
              <p>
                I based the app design on a tutorial by Jonah Lawrence called{" "}
                <a
                  href="https://www.youtube.com/watch?v=WZNG8UomjSI"
                  target="_blank"
                  rel="noreferrer"
                >
                  Build a Weather App with HTML, CSS & JavaScript."
                </a>{" "}
                I am pulling in JSON data from the{" "}
                <a
                  href="https://www.visualcrossing.com/weather-api"
                  target="_blank"
                  rel="noreferrer"
                >
                  Visual Crossing weather API
                </a>{" "}
                to determine whether the city you enter has snow either falling
                or on the ground by checking if any of the following data points
                return <code>true</code>:
              </p>
              <ul>
                <li>"snow" &gt; 0</li>
                <li>"icon" = "snow"</li>
                <li>"snowdepth" &gt; 0</li>
              </ul>
              <p>
                I hope one day to return to this project with more knowledge in
                order to create the app I orginally intended to create, but I
                did learn a lot about using API's and JSON data!
              </p>
              <h3>The code:</h3>
              <p>{CodepenSnowing()}</p>
            </>
          }
        />

        <PortfolioCard
          portfoliotitle="Whack-a-Zombie"
          portfoliotype="Game"
          portfoliolaunched="October 2021"
          portfoliolink="https://codeeverydamnday.com/projects/whackazombie/index.html"
          portfolioimage={whackazombie}
          alt="An image of a cartoon zombie coming out of its grave"
          description={
            <>
              <h3>Description:</h3>
              <p>
                On October 28, 2021, I challenged myself to build a spooky
                10-second game in 3 days—just in time for a Halloween release.
                Despite 2 of those 3 days being work days, I was able to code in
                the evenings and on a Saturday in order to get a functional (and
                fun!) game ready to play by Sunday the 31st.
              </p>
              <p>
                I built this game with HTML, CSS and JavaScript. Though it's
                templated off my previous games, I learned several new concepts
                about nested CSS Grids, callback functions and other properties
                I hadn't used before.
              </p>
              <h3>Game Synopsis:</h3>
              <p>
                Whack-a-Zombie is a creepy take on the classic whack-a-mole
                game. The zombies are taking over! You have 10 seconds to kill
                as many as possible before they leave their graves and take over
                the town.
              </p>
              <h3>The code:</h3>
              <p>{CodepenZombie()}</p>
            </>
          }
        />
        <PortfolioCard
          portfoliotitle={
            <>
              <em>Star Wars:</em> Galactic Starcruiser
            </>
          }
          portfoliotype="Website (with Disney)"
          portfoliolaunched="August 2021"
          portfoliolink="https://disneyworld.disney.go.com/star-wars-galactic-starcruiser/overview/"
          portfolioimage={swgs}
          alt="Poster art for Star Wars: Galactic Starcruiser, showing several Star Wars characters and droids superimposed over a rocky planet"
          description={
            <>
              <h3>Description:</h3>
              <p>
                On August 3, 2021, Disney launched a suite of web pages
                introducing <em>Star Wars:</em> Galactic Starcruiser—a 2-night
                adventure that immerses you in the world of <em>Star Wars</em>.
              </p>
              <p>
                While I didn't design or code these pages directly, I worked
                very closely with our developers for months to create new
                modules and components in our content management system that
                integrated with the developers' code and helped bring the
                designers' vision to life. I'm proud of how beautifully these
                pages turned out!
              </p>
            </>
          }
        />
        <PortfolioCard
          portfoliotitle="Rocket Blaster"
          portfoliotype="Game"
          portfoliolaunched="July 2021"
          portfoliolink="https://codeeverydamnday.com/projects/rocketblaster/index.html"
          portfolioimage={rocketblaster}
          alt="Cartoon rocket landing on the moon"
          description={
            <>
              <h3>Description:</h3>
              <p>
                I really enjoyed creating my last 10-second game, so I created
                another, called "Rocket Blaster." Rocket Blaster focuses heavily
                on CSS animations and uses JavaScript functions to control: 1.)
                an image switch depending whether a toggle is set to{" "}
                <code>true</code> or <code>false</code>, 2.) a gradual color and
                height change that manually changes RGB values and pixel heights
                with each click, and 3.) a choice of six post-game animations,
                one of which will play dependent on your score.
              </p>
              <h3>Game Synopsis:</h3>
              <p>
                You have 10 seconds to pump as much fuel as you can into a
                rocket. When time runs out, see how far your rocket flies across
                the galaxy!
              </p>
              <h3>The code:</h3>
              <p>{CodepenRocket()}</p>
            </>
          }
        />
        <PortfolioCard
          portfoliotitle="GPS Coordinates Converter"
          portfoliotype="Web App"
          portfoliolaunched="July 2021"
          portfoliolink="https://codeeverydamnday.com/projects/gpsconverter/index.html"
          portfolioimage={gps}
          alt="Screenshot of DD to DMS GPS Coordinates Converter App"
          description={
            <>
              <h3>Description:</h3>
              <p>
                This was an afternoon project that I built in a few hours to
                solve a rather niche problem of mine. I enjoy hiking in our
                beautiful national parks and forests, but many of these areas
                don't have any cell reception or WiFi. I wear a GPS watch that
                can navigate to any GPS coordinates I input even without
                service, as long as the coordinates are in DMS format (Degrees,
                Minutes, Seconds). Example: 47°29'32.6"N 120°49'59.3"W.
              </p>
              <p>
                However, Google Maps favors the DD coordinate format. Example:
                47.492400993416226, -120.83314130544642. It takes a few clunky
                steps to convert these coordinates to DMS format, so I created
                this DD to DMS GPS Coordinates Converter. I even enabled it as a
                web app for mobile, so I can add to my homescreen and use it as
                an app—even if I'm offline or have no service!
              </p>
              <h3>The code:</h3>
              <p>{CodepenGPS()}</p>
            </>
          }
        />
        <PortfolioCard
          portfoliotitle="Dragon Drop"
          portfoliotype="Game"
          portfoliolaunched="May 2021"
          portfoliolink="https://codeeverydamnday.com/projects/dragondrop/index.html"
          portfolioimage={dragondrop}
          alt="A blue cartoon fire-breathing dragon standing atop a pile of gold, silver and bronze coins"
          description={
            <>
              <h3>Description:</h3>
              <p>
                After learning how to create drag-and-drop elements from a fun
                LinkedIn Learning tutorial called "JavaScript: Events" by Ray
                Villalobos, I decided to create my own drag-and-drop game,
                appropriately name "Dragon Drop". I used HTML, CSS and vanilla
                JavaScript with a focus on event listeners. The game is also
                available as a web app.
              </p>
              <h3>Game Synopsis:</h3>
              <p>
                A dragon is hoarding all your money! You have 10 seconds to drag
                as many coins as you can into your vault.
              </p>
              <h3>The code:</h3>
              <p>{CodepenDragon()}</p>
            </>
          }
        />
        <PortfolioCard
          portfoliotitle="100 Days of Code"
          portfoliotype="Project"
          portfoliolaunched="April 2021"
          portfoliolink="https://twitter.com/search?q=%23100DaysOfCode%20%40planet_katie%20%22day%20%22&src=typed_query&f=live"
          portfolioimage={hundreddays}
          alt="Screenshot of some transparent code with the words '#100DaysOfCode' in white over the top"
          description={
            <>
              <h3>Description:</h3>
              <p>
                Shortly after I started building{" "}
                <a
                  href="https://codeeverydamnday.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  CodeEveryDamnDay.com
                </a>
                , I got more involved with the Dev community on Twitter and
                discovered a challenge called "#100DaysOfCode". The rules were
                simple:
              </p>
              <ol>
                <li>
                  Code for at least one hour every day for 100 consecutive days.
                </li>
                <li>
                  Tweet your progress on Twitter each day using the hashtag,
                  #100DaysOfCode.
                </li>
              </ol>
              <p>
                There were a few days in which I replaced actual coding with
                watching videos or reading articles about coding concepts I
                wanted to understand better, because I was visiting family or
                had a long day at work plus evening class. But for the most
                part, I did indeed code every day for 100 days straight and{" "}
                <a
                  href="https://twitter.com/search?q=%23100DaysOfCode%20%40planet_katie%20%22day%20%22&src=typed_query&f=live"
                  target="_blank"
                  rel="noreferrer"
                >
                  tweet my progress
                </a>{" "}
                each day.
              </p>
              <p>It was incredibly rewarding, and in that 100 days, I:</p>
              <ul>
                <li>Learned more advanced HTML and CSS</li>
                <li>Learned basic JavaScript, Python, SQL and Node</li>
                <li>Built a Portfolio website (this one)</li>
                <li>
                  Built two fully-functioning games using only HTML, CSS and
                  vanilla JavaScript (
                  <a
                    href="https://codeeverydamnday.com/projects/dragondrop/index.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Dragon Drop
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://codeeverydamnday.com/projects/rocketblaster/index.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Rocket Blaster
                  </a>
                  )
                </li>
                <li>
                  Built a web app (
                  <a
                    href="https://codeeverydamnday.com/projects/rocketblaster/index.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    GPS Coordinates Converter
                  </a>
                  )
                </li>
              </ul>
              <p>
                At times I felt like I wasn't learning fast enough, but looking
                back at everything, it's amazing how much I was able to
                accomplish in 100 days!
              </p>
            </>
          }
        />
        <PortfolioCard
          portfoliotitle="Code Every Damn Day"
          portfoliotype="Website"
          portfoliolaunched="April 2021"
          portfoliolink="https://codeeverydamnday.com/"
          portfolioimage={cedd}
          alt="The CodeEveryDamnDay.com homepage"
          description={
            <>
              <h3>Description:</h3>
              <p>
                While it may not be the most visually-stunning website in the
                world, everything on{" "}
                <a
                  href="https://codeeverydamnday.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  CodeEveryDamnDay.com
                </a>{" "}
                was hand-coded by me using HTML, CSS and JavaScript. When I
                started learning to code, I needed a place to practice the new
                concepts I was learning. I use this website as a
                work-in-progress experiment to test things out while also
                updating my blog each day with the new things I've learned. This
                is an ongoing project, as I'm sure I will modify many things on
                the site as I learn more.
              </p>
            </>
          }
        />
        <PortfolioCard
          portfoliotitle="Trace the Trail"
          portfoliotype="Website"
          portfoliolaunched="June 2020"
          portfoliolink="https://tracethetrail.com/"
          portfolioimage={tracethetrail}
          alt="The tracethetrail.com homepage"
          description={
            <>
              <h3>Description:</h3>
              <p>
                Trace the Trail is a WordPress site that I created in June 2020
                as a project to work on while I was furloughed from my job due
                to COVID-19. My goal was to get comfortable with creating,
                updating and manipulating WordPress sites.
              </p>
              <p>
                As a nature lover, I often run into photos of beautiful places
                that I'd like to visit, but have no idea where they are. I
                created Trace the Trail as a sort of "photo dart board" that
                showcases stunning photos of nature around the globe. Clicking
                each photo gives you detailed information about where the photo
                was taken from (exact coordinates if possible), how to get
                there, parking information, hiking information if applicable,
                and more at a glance!
              </p>
            </>
          }
        />
      </div>
    </div>
  );
}

export default PortfolioCards;
