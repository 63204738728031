import { Link } from "react-router-dom";
import BlogCard from "./BlogCard";
import technicaldocumentation from "../images/writing-technical-documentation.jpg";
import dynamicfooter from "../images/add-a-dynamic-footer.jpg";
import codesnippets from "../images/how-to-display-html-tags-with-custom-code-snippets.jpg";

function LatestBlog() {
  return (
    <div className="latestblog">
      <span className="blogtitle">
        <h1>Latest Blogs</h1>
      </span>
      <span className="blogtitlemobile">
        <h1>Latest Blog</h1>
      </span>
      <div className="blogpreviews">
        <div className="blogpreview">
          <span>
            <h3>How to Display HTML Tags with Custom Code Snippets</h3>
          </span>
          <span>October 24, 2021</span>
          <span>
            <img
              className="blogimage"
              src={codesnippets}
              alt="Cartoon drawing of an html tag, with two alligators forming the 'less than' and 'greater than' brackets with their open mouths"
            />
          </span>
          <span>
            A guide to creating your own code snippets instead of embedding them
            from third-party websites.
          </span>
          <span className="seemore">
            <Link to="/blog/custom-code-snippets">
              <h3>Read More &gt;</h3>
            </Link>
          </span>
        </div>
        <BlogCard
          blogtitle="Add a Dynamic Copyright Year to Your Website Footer in 60 Seconds"
          blogdate="October 3, 2021"
          blogimage={dynamicfooter}
          alt="Cartoon drawing of a frog jumping from one lilypad that says 'Dec 31' to another lilypad that says 'Jan 1', with cattails poking out of the water in the background"
          blogsnippet="Learn how to update the copyright year in your footer automatically using as few as two lines of code."
          bloglink="blog/dynamic-copyright-year"
        />
        <BlogCard
          blogtitle="Writing Technical Documentation So Easy, a Caveman Can Do It"
          blogdate="September 18, 2021"
          blogimage={technicaldocumentation}
          alt="Cartoon drawing of an owl sitting on a tree branch and fixing his glasses with one wing while holding a pencil with the other"
          blogsnippet="An article that explains how to write technical documentation—in the style of technical documentation."
          bloglink="blog/writing-technical-documentation"
        />
      </div>
      <span className="seealllink">
        <h2>
          <Link to="/blog">See All Blogs</Link>
        </h2>
      </span>
    </div>
  );
}

export default LatestBlog;
