import ResumeContent from "../components/ResumeContent";
import { Helmet } from "react-helmet";

function Resume() {
  return (
    <div>
      <Helmet>
        <title>Resume | Code Every Damn Day</title>
        <meta
          name="description"
          content="10+ years in web content management with additional experience in frontend web development, project management and marketing"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@planet_katie" />
        <meta name="twitter:creator" content="@planet_katie" />
        <meta name="twitter:image" content="../images/profilepic-resume.png" />
        <meta name="twitter:title" content="Resume | Katie Reynolds" />
        <meta
          name="twitter:description"
          content="10+ years in web content management with additional experience in frontend web development, project management and marketing"
        />
      </Helmet>
      <ResumeContent />
    </div>
  );
}

export default Resume;
