import { Link } from "react-router-dom";

import ProjectCard from "./ProjectCard";
import swgs from "../images/pf-swgs.png";
import whackazombie from "../images/pf-whckzmbie.png";

function FeaturedProject() {
  return (
    <div className="featuredproject">
      <span className="projecttitle">
        <h1>Featured Projects</h1>
      </span>
      <span className="projecttitlemobile">
        <h1>Featured Project</h1>
      </span>
      <div className="projectpreviews">
        <div className="projectpreview">
          <span>
            <h2>Whack-a-Zombie</h2>
          </span>
          <span>Project Type: Game</span>
          <span>
            <img
              className="projectimage"
              src={whackazombie}
              alt="Cartoon drawing of a zombie rising from its grave"
            />
          </span>
          <span>
            The zombies are trying to take over! You have 10 seconds to kill as
            many as possible before they leave their graves in this
            HTML/CSS/JavaScript-based game.
          </span>
          <span className="projectlink seemore">
            <a href="https://codeeverydamnday.com/projects/whackazombie/index.html">
              <h3>Play Game &gt;</h3>
            </a>
          </span>
        </div>
        <ProjectCard
          projecttitle={
            <>
              <em>Star Wars:</em> Galactic Starcruiser
            </>
          }
          projecttype="Project Type: Website (with Disney)"
          projectimage={swgs}
          alt="Poster art for Star Wars: Galactic Starcruiser, showing several Star Wars characters and droids superimposed over a rocky planetCartoon image of a rocket on top of the world"
          projectsnippet={
            <>
              <em>Star Wars:</em> Galactic Starcruiser is a 2-night adventure
              that immerses you in the world of <em>Star Wars</em>. I had the
              privilege of helping this suite of web pages come to life.
            </>
          }
          projectlink="https://disneyworld.disney.go.com/star-wars-galactic-starcruiser/overview/"
        />
      </div>
      <span className="seealllink">
        <h2>
          <Link to="/portfolio">See Full Portfolio</Link>
        </h2>
      </span>
    </div>
  );
}

export default FeaturedProject;
