function SkillItem(props) {
  return (
    <span className="skillicon">
      <img src={props.icon} alt={props.alt} className="skillsimage" />
      <br />
      <span>{props.text}</span>
    </span>
  );
}

export default SkillItem;
