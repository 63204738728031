import { Link } from "react-router-dom";

function BlogPreviewCard(props) {
  return (
    <div className="blogpreviewcard">
      <div>
        <h2>{props.blogpreviewtitle}</h2>
      </div>
      <div className="blogintrocopy">{props.blogpreviewdate}</div>
      <div>
        <img
          className="blogpreviewimage"
          src={props.blogpreviewimage}
          alt={props.alt}
        />
      </div>
      <div className="blogintrocopy">{props.blogpreviewsnippet}</div>
      <span className="blogpreviewlink seemore">
        <Link to={props.blogpreviewlink}>
          <h3 className="seedetails">Read More &rarr;</h3>
        </Link>
      </span>
    </div>
  );
}

export default BlogPreviewCard;
