import { Link } from "react-router-dom";
import preview from "../../images/how-to-display-html-tags-with-custom-code-snippets.jpg";
import escape1 from "../../images/custom-code-snippets-escape-1.png";
import escape2 from "../../images/custom-code-snippets-escape-2.png";
import overflow from "../../images/custom-code-snippets-overflow.png";
import Codepen from "react-codepen-embed";
import { Helmet } from "react-helmet";

function CustomCodeSnippets() {
  const CodepenEmbed = () => {
    return <Codepen hash="gOxGPdM" user="reynoldsmkatie" />;
  };
  return (
    <div className="allblogcontent">
      <Helmet>
        <title>
          How to Display HTML Tags with Custom Code Snippets | Code Every Damn
          Day
        </title>
        <meta
          name="description"
          content="A guide to creating your own code snippets instead of embedding them from third-party websites."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@planet_katie" />
        <meta name="twitter:creator" content="@planet_katie" />
        <meta
          name="twitter:image"
          content="../images/how-to-display-html-tags-with-custom-code-snippets.jpg"
        />
        <meta
          name="twitter:title"
          content="How to Display HTML Tags with Custom Code Snippets"
        />
        <meta
          name="twitter:description"
          content="A guide to creating your own code snippets instead of embedding them from third-party websites."
        />
      </Helmet>
      <div className="blogcontent">
        <div className="blogwrapper">
          <h2>How to Display HTML Tags with Custom Code Snippets</h2>
          <p className="blogauthor">
            <strong>Author:</strong> Katie Reynolds
          </p>
          <p className="blogpublished">
            <strong>Published:</strong> October 24, 2021
          </p>
          <img
            src={preview}
            alt="Cartoon drawing of an html tag, with two alligators forming the 'less than' and 'greater than' brackets with their open mouths"
          />
          <p>
            Everyone remembers their first time. That is, the first time they
            tried to type some html into a comment or blog and it ended up
            looking like this:
          </p>
          <p>
            <pre className="prettyprint">
              <div>Some content inside a div.</div>
            </pre>
          </p>
          <p>Instead of like this:</p>
          <pre className="prettyprint">
            &lt;div&gt;Some content inside a div.&lt;/div&gt;
          </pre>
          <p>
            If you've ever needed to display html tags, you've probably
            encountered the difficulties of sharing code. Most text editors will
            try to strip out your html tags, or treat your code as
            <em>actual</em> code and attempt to respect the rules of the code
            you've written. That is to say, if you type a
            <code> &lt;div&gt;</code> tag, it will actually create a
            <code> &lt;div&gt;</code> container instead of showing the
            <code> &lt;div&gt;</code> tag verbatim.
          </p>
          <h2>Embedding Third-Party Code Snippets: Pros and Cons</h2>
          <p>
            There are several third-party tools you can use to embed code
            snippets into your web page, such as
            <a href="https://codepen.io/" target="_blank" rel="noreferrer">
              {" "}
              CodePen
            </a>{" "}
            and
            <a href="https://jsfiddle.net/" target="_blank" rel="noreferrer">
              {" "}
              JSFiddle
            </a>
            . These services allow you to paste HTML, CSS and JavaScript code
            into predefined columns, and even show you the visual representation
            of your code in a "Result" area. You can then embed the code blocks
            into your web pages with some generated embed code.
          </p>
          <p>Here is an example of an embedded CodePen snippet:</p>
          <p>{CodepenEmbed()}</p>
          <p>
            I love these tools for sharing larger projects with a lot of code,
            because:
          </p>
          <ul>
            <li>
              They format your code nicely, highlighting syntax so it's easy to
              distinguish between tags and content
            </li>
            <li>
              They allow you to easily embed or link to your "Pen" or "Fiddle"
              wherever you want.
            </li>
            <li>
              They allow for a large amount code to be placed inside a preset
              container with scroll bars, so that your code doesn't take up
              hundreds or thousands of lines on your web page.
            </li>
          </ul>
          <p>
            However, third-party tools like this do have a few disadvantages:
          </p>
          <ul>
            <li>
              The embedded code snippet will probably have the website's logo on
              it somewhere.
            </li>
            <li>
              You can't customize the embedded snippet much, besides choosing a
              light vs. dark theme and the default tab to show.
            </li>
            <li>
              It's a bit overkill to embed an entire third-party box onto your
              page when you only need to share a line or two of code.
            </li>
            <li>Embedded content can slow down your website's loading times</li>
            <li>
              If the third-party website ever goes out of business or stops
              supporting embeds, you may lose all of your existing code
              snippets. That could have a big impact on any past blogs you've
              written that included code snippets.
            </li>
          </ul>
          <p>
            In order to have more control over my own website and blogs, I
            prefer to spend a little extra time creating my own custom code
            snippets using HTML's built-in tags for displaying code. This way, I
            have the peace of mind knowing that my code snippets are not
            dependent on any outside resources.
          </p>
          <h2>
            The <code>&lt;code&gt;&lt;/code&gt;</code> Tag
          </h2>
          <p>
            If you have a simple word or single line of code to share, you can
            put it inside the <code>&lt;code&gt;&lt;/code&gt;</code> tag. That
            will format your snippet as monospaced text. However, there are a
            few common characters that still won't show when you view a preview
            of your code snippet:
          </p>
          <div className="customcodetable">
            <span className="teamtableheader">
              <strong>To show this:</strong>
            </span>
            <span className="teamtableheader">
              <strong>You would type:</strong>
            </span>
            <span className="teamtablelightgray">&lt;</span>
            <span className="teamtablelightgray">&amp;lt;</span>
            <span className="teamtabledarkgray">&gt;</span>
            <span className="teamtabledarkgray">&amp;gt;</span>
            <span className="teamtablelightgray">&quot;</span>
            <span className="teamtablelightgray">&amp;quot;</span>
            <span className="teamtabledarkgray">&amp;</span>
            <span className="teamtabledarkgray">&amp;amp;</span>
          </div>
          <p>
            This is called "escaping" the characters. Based on the rules above,
            if you wanted to display the line:
          </p>
          <pre className="prettyprint">
            &lt;div&gt;Some content inside a div.&lt;/div&gt;
          </pre>
          <p>
            What you would <em>actually</em> type in between the
            <code> code</code> tags is:
          </p>
          <pre className="prettyprint">
            &amp;lt;div&amp;gt;Some content inside a div.&amp;lt;/div&amp;gt;
          </pre>
          <p>That will show your open and closing brackets correctly.</p>
          <blockquote>
            <strong>Tip:</strong> If you'll be using these characters in your
            code often, it's a good idea to memorize them. An easy way to
            remember the character codes for <code>&lt;</code> and
            <code> &gt;</code> is that <code>&amp;lt;</code> stands for "less
            than" and <code>&amp;gt;</code> stands for "greater than." Quotes
            and ampersands are a little easier to remember, as
            <code> &amp;quot;</code> and <code>&amp;amp;</code> respectively.
          </blockquote>
          <p>
            Typing your escaped characters out like this can quickly become a
            pain if you have a lot of code with a lot of brackets or quotation
            marks, so there are tools online that will "escape" all of the
            necessary characters for you when you paste in your code.
          </p>
          <p>
            I personally use the
            <a
              href="https://www.freeformatter.com/html-escape.html"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              HTML Escape tool at FreeFormatter.com
            </a>
            . To use this tool:
          </p>
          <ol>
            <li>
              Paste your code snippet or block into the text area at the top of
              the page, like this:
              <br />
              <br />
              <img
                src={escape1}
                alt="Screenshot of an HTML Escape Tool from FreeFormatter.com showing a block of html code pasted into a text area"
              />
            </li>
            <br />
            <li>
              Click the "Escape" button. (There is also an "Unescape" button, if
              you need to convert the other way around)
            </li>
            <br />
            <li>
              The escaped version of your code will show in the text area below
              your original code.
              <br />
              <br />
              <img
                src={escape2}
                alt="Screenshot of an HTML Escape Tool from FreeFormatter.com showing a block of html code that has been converted into it's escaped version."
              />
            </li>
            <br />
            <li>
              Copy the escaped version of the code and paste it in between your
              <code> code</code> tags.
            </li>
          </ol>
          <h2>
            The <code>&lt;pre&gt;</code> Tag
          </h2>
          <p>
            What if you have multiple lines of code, and you want your text
            editor to respect the line breaks and spacing that you add to your
            code snippet? That's where the <code>&lt;pre&gt;</code> tag comes in
            handy.
          </p>
          <p>
            The <code>&lt;pre&gt;</code> tag works almost exactly the same as
            the <code>&lt;code&gt;</code> tag, but it will respect any line
            breaks or spaces you add into your code block.
          </p>
          <p>
            For example, if you try to type a snippet like this in between the
            <code> &lt;code&gt;</code> tags, hitting the enter key between each
            line...
          </p>
          <p>
            <pre className="prettyprint">
              &lt;p&gt;Some content&lt;/p&gt;
              <br />
              &lt;p&gt;Another line of content&lt;/p&gt;
              <br />
              &lt;p&gt;Yet another line of content&lt;/p&gt;
            </pre>
          </p>
          <p>
            ... you'll actually get something like this on your page, because
            the <code>&lt;code&gt;</code> tag does not respect spacing or line
            breaks:
          </p>
          <pre className="prettyprint">
            &lt;p&gt;Some content&lt;/p&gt; &lt;p&gt;Another line of
            content&lt;/p&gt; &lt;p&gt;Yet another line of content&lt;/p&gt;
          </pre>
          <p>
            You could work around this by adding unescaped
            <code> &lt;br&gt;</code> tags between each line (actually typing the
            &lt; and &gt; characters instead of their escaped versions), but the
            <code> &lt;pre&gt;</code> tag is a better solution. You can simply
            type your escaped code as-is between the
            <code> &lt;pre&gt;</code> tags, entering any spacing you would like,
            and the spacing will show exactly as you've typed it in your text
            editor.
          </p>
          <p>Here's how an unordered list might look:</p>
          <pre className="prettyprint">
            A simple list:
            <br />
            <br />
            &lt;ul&gt;
            <br />
            &nbsp;&nbsp;&lt;li&gt;Item 1&lt;/li&gt;
            <br />
            &nbsp;&nbsp;&lt;li&gt;Item 2&lt;/li&gt;
            <br />
            &nbsp;&nbsp;&lt;li&gt;Item 3&lt;/li&gt;
            <br />
            &lt;/ul&gt;
          </pre>
          <blockquote>
            Note: I personally have some CSS styling that formats anything
            inside of a <code>&lt;pre&gt;</code> tag with a dark background and
            syntax highlighting, so yours won't look like this by default. I
            explain how I styled mine in the "But How Can I Make It{" "}
            <em>Pretty</em>?" section below.
          </blockquote>
          <p>
            In order to get the above block to show the way it does above, this
            is exactly what I typed:
          </p>
          <pre className="prettyprint">
            &lt;pre&gt;A simple list:
            <br />
            <br />
            &amp;lt;ul&amp;gt;
            <br />
            &nbsp;&nbsp;&amp;lt;li&amp;gt;Item 1&amp;lt;/li&amp;gt;
            <br />
            &nbsp;&nbsp;&amp;lt;li&amp;gt;Item 2&amp;lt;/li&amp;gt;
            <br />
            &nbsp;&nbsp;&amp;lt;li&amp;gt;Item 3&amp;lt;/li&amp;gt;
            <br />
            &amp;lt;/ul&amp;gt;&lt;/pre&gt;
          </pre>
          <p>
            The spaces before the list items are actually just two spaces I
            indented in my code editor, not <code>&amp;nbsp;</code>'s or
            anything like that. Likewise, I created the line break between "A
            simple list:" and the list itself by hitting "Enter" twice on my
            keyboard, not by adding a couple of <code>&lt;br&gt;</code> tags
            into my code.
          </p>
          <h2>
            But How Can I Make It <em>Pretty</em>?
          </h2>
          <p>
            Between the <code>&lt;code&gt;</code> tag, the
            <code> &lt;pre&gt;</code> tag, and the knowledge you have about
            converting reserved characters into escaped characters, you should
            be able to add any custom code snippet into your comment, blog post
            or technical documentation (as long as your text editor supports
            these HTML tags).
          </p>
          <p>
            That said, your code snippets will still be plain black and it may
            be hard to distinguish between tags and content at a glance. How can
            you add some nice colors and styling to your code blocks the way
            that CodePen or Fiddle does?
          </p>
          <p>
            To start, I added a CSS style to my main stylesheet that turns
            anything inside <code>&lt;code&gt;</code> tags the color "tomato."
          </p>
          <pre className="prettyprint">
            code &#123;
            <br />
            &nbsp;&nbsp;color: tomato;
            <br />
            &#125;
          </pre>
          <p>
            That's why you are seeing a reddish color on everything on this page
            that I've put inside of a <code>&lt;code&gt;</code> tag. These are
            usually just single words or one-line items, so I didn't get too
            crazy with it.
          </p>
          <p>
            I added a bit more styling to anything I put inside of{" "}
            <code>&lt;pre&gt;</code> tags, since those tend to be longer blocks
            in which I'm trying to demonstrate some code.
          </p>
          <pre className="prettyprint">
            pre &#123;
            <br />
            &nbsp;&nbsp;word-break: break-word;
            <br />
            &nbsp;&nbsp;white-space: pre-wrap;
            <br />
            &nbsp;&nbsp;background-color: #eeeeee;
            <br />
            &nbsp;&nbsp;padding: 20px;
            <br />
            &nbsp;&nbsp;border: none;
            <br />
            &#125;
          </pre>
          <ol>
            <li>
              <code>word-break: break-word;</code> This is important! This
              property will break a long word right in the middle in order to
              prevent the container from stretching to accommodate the text. If
              this property were omitted, a long URL or other string of text
              without spaces or other natural breaks would not wrap to the next
              line, causing the container bounds to stretch off the page like
              this, especially in a mobile view:
            </li>
            <p>
              <img
                src={overflow}
                alt="Example of a code snippet that overflows the bounds of its container due to a long URL"
              />
            </p>
            <li>
              <code>white-space: pre-wrap;</code> respects the space defined
              within the <code>pre</code> tag and also wraps the text when
              necessary to keep it within the container.
            </li>
            <li>
              <code>background-color: #eeeeee;</code> This one is just a
              fallback for me. It would give my <code>&lt;pre&gt;</code> tags a
              nice light gray background color, if I weren't pulling the current
              background color from somewhere else (more on that below).
            </li>
            <li>
              <code>padding: 20px;</code> gives the code some padding between
              itself and the container bounds
            </li>
            <li>
              <code>border: none;</code> removes any borders around the
              container.
            </li>
          </ol>
          <p>
            Finally, you'll notice that my <code>pre</code> blocks have a dark
            background and some colored syntax highlighting in them. Admittedly,
            I do use a third-party service called{" "}
            <a
              href="https://github.com/googlearchive/code-prettify"
              target="_blank"
              rel="noreferrer"
            >
              Google Prettify
            </a>{" "}
            for this. I don't mind as much, because if Google were to stop
            supporting this service, my code would remain intact—it would simply
            lose its color, which isn't the end of the world.
          </p>
          <p>
            To use Google Prettify, simply add this snippet somewhere near the
            top of your page's <code>&lt;head&gt;</code> section:
          </p>
          <pre className="prettyprint">
            &lt;script
            src="https://cdn.jsdelivr.net/gh/google/code-prettify@master/loader/run_prettify.js?skin=desert"&gt;&lt;/script&gt;
          </pre>
          <p>
            I am using the "desert" theme, as you can see from the "skin"
            property at the end of my URL. There are a few other themes from
            which you can choose on the{" "}
            <a
              href="https://raw.githack.com/google/code-prettify/master/styles/index.html"
              target="_blank"
              rel="noreferrer"
            >
              Google Prettify Theme Gallery
            </a>{" "}
            page. Just replace "desert" with your theme of choice.
          </p>
          <p>
            Then, to apply the styling to your code snippets, add{" "}
            <code>class=&quot;prettyprint&quot;</code> to all of your opening{" "}
            <code>&lt;pre&gt;</code> tags. For example:
          </p>
          <pre className="prettyprint">
            &lt;pre class=&quot;prettyprint&quot;&gt;Your Code Snippet
            Here&lt;/pre&gt;
          </pre>
          <p>
            It might seem like a lot of extra work to get all of this set up,
            but if you share code snippets often, you'll get the hang of it
            pretty quickly. It's reassuring to know that you have control over
            your own code snippets, and that you won't have to rely on the
            continued support of third-party websites. Your content is your own!
          </p>
        </div>
        <h3 className="backtoallblogs">
          <Link to="/blog">&larr; Back to All Blogs</Link>
        </h3>
      </div>
    </div>
  );
}

export default CustomCodeSnippets;
