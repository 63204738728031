import profilepic from "../images/profile-deception-pass.jpg";

function About() {
  return (
    <div className="about">
      <div className="aboutinfo">
        <span className="profilepic">
          <img
            className="profileimage"
            src={profilepic}
            alt="Girl standing on some driftwood on the beach in Deception Pass, Washington"
          />
        </span>
        <span className="profilesummary">
          <h3 id="note">
            <strong>
              NOTE: My new website is located at{" "}
              <a href="https://www.thediydev.com/">thediydev.com</a>. This site
              is outdated, but remains live for historical purposes.
            </strong>
          </h3>
          <br />
          <h2>Katie Reynolds</h2>
          <h3>Web Content Manager &amp; Front-End Developer</h3>
          <p className="aboutbullets">
            10+ years managing web content for both small agencies and large
            companies (currently @ Matterport, formerly @ Disney)
          </p>
          <p className="aboutbullets">
            Learning (and loving!) front-end development by creating websites,
            apps and games from scratch
          </p>
          <p className="aboutbullets">
            Hobbies include hiking, building LEGO and watching rocket launches
          </p>
        </span>
      </div>
    </div>
  );
}

export default About;
