import PortfolioCards from "../components/PortfolioCards";
import { Helmet } from "react-helmet";

function Portfolio() {
  return (
    <div>
      <Helmet>
        <title>Porfolio | Code Every Damn Day</title>
        <meta
          name="description"
          content="A collection of my websites, games, apps and other projects"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@planet_katie" />
        <meta name="twitter:creator" content="@planet_katie" />
        <meta name="twitter:image" content="../images/whckzmbie.png" />
        <meta name="twitter:title" content="Portfolio | Katie Reynolds" />
        <meta
          name="twitter:description"
          content="A collection of my websites, games, apps and other projects"
        />
      </Helmet>
      <PortfolioCards />
    </div>
  );
}

export default Portfolio;
