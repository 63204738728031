import { Link } from "react-router-dom";

function ProjectCard(props) {
  return (
    <div className="projectpreview otherprojects">
      <span>
        <h2>{props.projecttitle}</h2>
      </span>
      <span>{props.projecttype}</span>
      <span>
        <img
          className="projectimage"
          src={props.projectimage}
          alt={props.alt}
        />
      </span>
      <span>{props.projectsnippet}</span>
      <span className="projectlink seemore">
        <Link to={props.projectlink}>
          <h3>View Project &gt;</h3>
        </Link>
      </span>
    </div>
  );
}

export default ProjectCard;
