function Footer() {
  return (
    <div className="footer">
      <div>
        <span>&copy; 2022 by Katie Reynolds</span>
      </div>
    </div>
  );
}

export default Footer;
